import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgModuleProductionIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z"
        fill="#FFEDD1"
      />
      <path
        d="m28.866 25.53-7.895-7.895c.78-1.996.347-4.338-1.302-5.987-1.735-1.735-4.337-2.082-6.42-1.127l3.731 3.73-2.603 2.603-3.817-3.73c-1.041 2.082-.607 4.684 1.128 6.42 1.648 1.648 3.99 2.082 5.986 1.3l7.895 7.896a.839.839 0 0 0 1.215 0l1.995-1.996c.434-.347.434-.954.087-1.214Z"
        fill="#E38800"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgModuleProductionIcon);
export default ForwardRef;
