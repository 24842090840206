import { cn } from '../../utils';
import { ExpandMoreIcon } from '@in2event/icons';
import * as SelectPrimitive from '@radix-ui/react-select';
import type { ComponentPropsWithoutRef, ElementRef, SelectHTMLAttributes } from 'react';
import { forwardRef } from 'react';

const variantClass = {
    compact: 'py-1.5 px-2 h-8 pr-6',
    default: 'py-2.5 px-3 h-10 pr-8',
};
type InputVariant = keyof typeof variantClass;

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    variant?: InputVariant;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
    ({ name, className, variant = 'default', ...props }, forwardedRef) => (
        <select
            ref={forwardedRef}
            role="combobox"
            name={name}
            id={name}
            className={cn(
                'form-select flex w-full truncate rounded-md border-none bg-neutral-2100 text-sm font-medium text-neutral-250 placeholder:text-sm placeholder:font-medium placeholder:text-neutral-50',
                'hover:bg-neutral-2000 focus:border-2 focus:border-primary-600 focus:outline-none focus:ring-0',
                'disabled:cursor-not-allowed disabled:border-none disabled:bg-neutral-4 disabled:text-neutral-1100',
                variantClass[variant],
                className,
            )}
            {...props}
        />
    ),
);

Select.displayName = 'Select';

const SelectRoot = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectItemText = SelectPrimitive.ItemText;

const SelectIcon = SelectPrimitive.Icon;

const SelectTrigger = forwardRef<
    ElementRef<typeof SelectPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            'flex h-10 items-center justify-center rounded-md border-none bg-neutral-2100 p-2 text-sm font-medium text-neutral-250 outline-none data-[placeholder]:text-sm data-[placeholder]:font-medium data-[placeholder]:text-neutral-50',
            'disabled:cursor-not-allowed disabled:border-none disabled:bg-neutral-4 disabled:text-neutral-1100',
            className,
        )}
        {...props}
    >
        {children}
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = forwardRef<
    ElementRef<typeof SelectPrimitive.Content>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'item-aligned', ...props }, ref) => (
    <SelectPrimitive.Portal>
        <SelectPrimitive.Content
            ref={ref}
            className={cn(
                'relative z-50 min-w-32 overflow-hidden rounded-md border bg-white text-neutral-250 shadow-elevation data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                position === 'popper' &&
                    'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
                className,
            )}
            position={position}
            {...props}
        >
            <SelectPrimitive.ScrollUpButton className="flex h-[25px] cursor-default items-center justify-center bg-white">
                <ExpandMoreIcon className="size-5 rotate-180" />
            </SelectPrimitive.ScrollUpButton>
            <SelectPrimitive.Viewport
                className={cn(
                    'p-1',
                    position === 'popper' &&
                        'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
                )}
            >
                {children}
            </SelectPrimitive.Viewport>
            <SelectPrimitive.ScrollDownButton className="flex h-[25px] cursor-default items-center justify-center bg-white">
                <ExpandMoreIcon className="size-5" />
            </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = forwardRef<
    ElementRef<typeof SelectPrimitive.Label>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
        {...props}
    />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = forwardRef<
    ElementRef<typeof SelectPrimitive.Item>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            'relative flex w-full cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm text-base-900 outline-none focus:bg-primary-200 data-[disabled]:cursor-not-allowed',
            className,
        )}
        {...props}
    />
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = forwardRef<
    ElementRef<typeof SelectPrimitive.Separator>,
    ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cn('bg-muted -mx-1 my-1 h-px', className)}
        {...props}
    />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
    SelectRoot,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectItemText,
    SelectIcon,
};
