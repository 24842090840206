import { EditNoteIcon } from '@in2event/icons';
import type { Row, Table } from '@tanstack/react-table';
import type { HTMLAttributes, Ref } from 'react';
import { forwardRef } from 'react';
import { cn } from '../../../utils';
import { IndeterminateCheckbox } from '../../checkbox';
import { Button } from '../../button';

/* -------------------------------------------------------------------------------------------------
 * Table selection toggler
 * -----------------------------------------------------------------------------------------------*/

export interface RowSelectionTogglerProps extends HTMLAttributes<HTMLDivElement> {
    rowSelectionEnabled: boolean;
    setRowSelectionEnabled: (enable: boolean) => void;
}

const RowSelectionToggler = forwardRef<HTMLDivElement, RowSelectionTogglerProps>(
    ({ className, rowSelectionEnabled, setRowSelectionEnabled, ...props }, forwardedRef) => {
        return (
            <div
                ref={forwardedRef}
                role="button"
                className={cn(
                    'flex size-8 items-center justify-center gap-2.5 rounded-md bg-neutral-6',
                    className,
                )}
                onClick={() => setRowSelectionEnabled(!rowSelectionEnabled)}
                {...props}
            >
                <EditNoteIcon
                    className={cn('size-5', {
                        'fill-blue-600': rowSelectionEnabled,
                    })}
                />
            </div>
        );
    },
);

RowSelectionToggler.displayName = 'RowSelectionToggler';

/* -------------------------------------------------------------------------------------------------
 * Table header sorting field
 * -----------------------------------------------------------------------------------------------*/

function TableHeaderSelectionField<T>({
    table,
    className,
    disabled,
}: {
    table: Table<T>;
    className?: string;
    disabled?: boolean;
}) {
    return (
        <IndeterminateCheckbox
            {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                className: cn('mt-1.5 max-md:ml-1', className),
                disabled,
            }}
        />
    );
}

TableHeaderSelectionField.displayName = 'TableHeaderSelectionField';

/* -------------------------------------------------------------------------------------------------
 * Table body sorting field
 * -----------------------------------------------------------------------------------------------*/

function TableBodySelectionField<T>({
    row,
    className,
    disabled,
}: {
    row: Row<T>;
    className?: string;
    disabled?: boolean;
}) {
    return (
        <>
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    indeterminate: row.getIsSomeSelected(),
                    onChange: row.getToggleSelectedHandler(),
                    className,
                    disabled,
                }}
            />
        </>
    );
}

TableBodySelectionField.displayName = 'TableBodySelectionField';

/* -------------------------------------------------------------------------------------------------
 * Selected rows indicator
 * -----------------------------------------------------------------------------------------------*/

interface SelectedRowsIndicatorProps {
    title: string;
    rows: number;
    selectedRows: number;
    onReset: () => void;
    mRef?: Ref<HTMLDivElement> | null;
}

function SelectedRowsIndicator({
    title,
    rows,
    selectedRows,
    mRef,
    onReset,
}: SelectedRowsIndicatorProps) {
    return (
        <div className="flex items-center gap-2" ref={mRef}>
            <h5 className="text-lg font-bold leading-6 tracking-[-0.18px] text-neutral-250">
                {!selectedRows ? `${rows} ${title ?? 'rows'}` : `${selectedRows} selected`}
            </h5>
            {selectedRows > 0 && (
                <Button variant="ghost" className="text-neutral-60" onClick={onReset}>
                    clear
                </Button>
            )}
        </div>
    );
}

SelectedRowsIndicator.displayName = 'SelectedRowsIndicator';

/**
 * Exports
 */

export {
    RowSelectionToggler,
    TableHeaderSelectionField,
    TableBodySelectionField,
    SelectedRowsIndicator,
};
