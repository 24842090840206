import { cn } from '../../utils';
import { Slot } from '@radix-ui/react-slot';
import { forwardRef } from 'react';
import type { ReactNode } from 'react';

type MenuLinkProps = {
    children: ReactNode;
    isActive?: boolean;
    className?: string;
    asChild?: boolean;
};

export const MenuLink = forwardRef<HTMLAnchorElement, MenuLinkProps>(
    ({ className, children, isActive, asChild = false, ...props }, forwardedRef) => {
        const Comp = asChild ? Slot : 'a';

        return (
            <Comp
                className={cn(
                    'group flex h-10 shrink-0 items-center justify-between gap-4 rounded px-3 py-0 text-sm font-semibold leading-8 text-[#999] hover:bg-neutral-2210 hover:text-white',
                    { 'text-white bg-neutral-2210': isActive },
                    className,
                )}
                {...props}
                ref={forwardedRef}
            >
                {children}
            </Comp>
        );
    },
);

MenuLink.displayName = 'MenuLink';
