import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgSettingsIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M16.25 10a6.47 6.47 0 0 0-.025-.567l1.55-1.175a.838.838 0 0 0 .217-1.083l-1.559-2.692a.822.822 0 0 0-1.041-.35l-1.792.759a6.328 6.328 0 0 0-.975-.567L12.383 2.4a.836.836 0 0 0-.825-.733H8.45a.838.838 0 0 0-.833.733l-.242 1.925c-.342.158-.667.35-.975.567l-1.792-.759a.822.822 0 0 0-1.041.35l-1.559 2.7a.84.84 0 0 0 .217 1.084l1.55 1.175a6.118 6.118 0 0 0 0 1.125l-1.55 1.175a.838.838 0 0 0-.217 1.083l1.559 2.692a.822.822 0 0 0 1.041.35l1.792-.759c.308.217.633.409.975.567l.242 1.925c.05.417.408.733.825.733h3.108a.836.836 0 0 0 .825-.733l.242-1.925c.341-.158.666-.35.975-.567l1.791.759a.822.822 0 0 0 1.042-.35l1.558-2.692a.84.84 0 0 0-.216-1.083l-1.55-1.175c.025-.192.033-.375.033-.567Zm-6.217 2.917A2.92 2.92 0 0 1 7.117 10a2.92 2.92 0 0 1 2.916-2.917A2.92 2.92 0 0 1 12.95 10a2.92 2.92 0 0 1-2.917 2.917Z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgSettingsIcon);
export default ForwardRef;
