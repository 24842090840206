import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgHikingIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <g mask="url(#hiking-icon_svg__a)">
                <path
                    d="m10.9 15-1.625 7.2a.968.968 0 0 1-.363.575c-.191.15-.412.225-.662.225a.973.973 0 0 1-.8-.375.976.976 0 0 1-.2-.85L10.075 7.45c.1-.483.325-.846.675-1.088.35-.241.717-.362 1.1-.362.383 0 .738.083 1.063.25.325.167.587.417.787.75l1 1.6c.3.483.688.92 1.163 1.313.474.391 1.02.679 1.637.862V9.75a.728.728 0 0 1 .75-.75.729.729 0 0 1 .75.75v12.5a.729.729 0 0 1-.75.75.728.728 0 0 1-.75-.75v-9.4a7.842 7.842 0 0 1-2.225-.875A6.924 6.924 0 0 1 13.5 10.5l-.6 3 1.8 1.7c.1.1.175.213.225.338.05.124.075.254.075.387V22c0 .283-.096.52-.287.712A.968.968 0 0 1 14 23a.967.967 0 0 1-.713-.288A.968.968 0 0 1 13 22v-5l-2.1-2Zm-4.45-2.05-1.15-.225a.928.928 0 0 1-.625-.412c-.15-.226-.2-.48-.15-.763l.75-3.925c.1-.567.4-.996.9-1.287.5-.292 1.042-.38 1.625-.263a.9.9 0 0 1 .637.412c.142.226.188.48.138.763l-.95 4.9a.942.942 0 0 1-.413.65c-.225.15-.479.2-.762.15ZM13.5 5.5c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0 1 11.5 3.5c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 13.5 1.5c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413s-.196 1.02-.588 1.412A1.926 1.926 0 0 1 13.5 5.5Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
const ForwardRef = forwardRef(SvgHikingIcon);
export default ForwardRef;
