import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgRemoveIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 13H5v-2h14v2z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgRemoveIcon);
export default ForwardRef;
