import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgTeamIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M13.333 9.167a2.49 2.49 0 0 0 2.492-2.5 2.497 2.497 0 1 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5Zm-6.666 0a2.49 2.49 0 0 0 2.491-2.5 2.497 2.497 0 1 0-4.992 0c0 1.383 1.117 2.5 2.5 2.5Zm0 1.666c-1.942 0-5.834.975-5.834 2.917V15c0 .458.375.833.834.833h10A.836.836 0 0 0 12.5 15v-1.25c0-1.942-3.892-2.917-5.833-2.917Zm6.666 0c-.241 0-.516.017-.808.042.017.008.025.025.033.033.95.692 1.609 1.617 1.609 2.842V15c0 .292-.059.575-.15.833h4.316a.836.836 0 0 0 .834-.833v-1.25c0-1.942-3.892-2.917-5.834-2.917Z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgTeamIcon);
export default ForwardRef;
