import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

export const DataTableMobileRow = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    (props, ref) => {
        return (
            <div
                {...props}
                ref={ref}
                className="flex items-start justify-between gap-base px-4 py-3.5"
            />
        );
    },
);

DataTableMobileRow.displayName = 'DataTableMobileRow';

export const DataTableMobileRowPrimaryText = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement>
>((props, ref) => {
    return <div {...props} ref={ref} className="text-sm font-medium text-neutral-250" />;
});

DataTableMobileRowPrimaryText.displayName = 'DataTableMobileRowPrimaryText';

export const DataTableMobileRowSecondaryText = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement>
>((props, ref) => {
    return <div {...props} ref={ref} className="text-[13px] leading-5 text-neutral-60" />;
});

DataTableMobileRowSecondaryText.displayName = 'DataTableMobileRowSecondaryText';
