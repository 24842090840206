import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgSendMessageIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgSendMessageIcon);
export default ForwardRef;
