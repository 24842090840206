import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgDatabaseIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M16.667 1.667h-10C5.75 1.667 5 2.417 5 3.333v10C5 14.25 5.75 15 6.667 15h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666Zm-6.867 9.4L8.075 9.333a.835.835 0 0 1 0-1.166.83.83 0 0 1 1.175 0l1.133 1.141 3.684-3.716a.83.83 0 0 1 1.175 0 .835.835 0 0 1 0 1.166l-4.275 4.309a.806.806 0 0 1-1.167 0ZM2.5 5a.836.836 0 0 0-.833.833v10.834c0 .916.75 1.666 1.666 1.666h10.834A.836.836 0 0 0 15 17.5a.836.836 0 0 0-.833-.833h-10a.836.836 0 0 1-.834-.834v-10A.836.836 0 0 0 2.5 5Z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgDatabaseIcon);
export default ForwardRef;
