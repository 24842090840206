'use client';

import { motion } from 'framer-motion';
import { ReactNode } from 'react';

export const UploaderProgress = ({
    children,
    progress,
}: {
    children: ReactNode;
    progress: number;
}) => {
    const isProgressNearComplete = progress > 85;
    const isProgressComplete = progress === 100;

    if (isProgressComplete) {
        return (
            <div className="relative flex h-14 w-full items-center gap-3 overflow-hidden rounded-lg border-2 p-2 text-center">
                {children}
            </div>
        );
    }

    return (
        <motion.div className="relative flex h-14 w-full items-center gap-3 overflow-hidden rounded-lg border-2 p-2 text-center">
            <motion.div
                style={{
                    width: `${String(progress)}%`,
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    background: 'linear-gradient(to right, #DCDCDC, #B9B9B9, #8B8B8B)',
                    borderRadius: isProgressNearComplete ? 'inherit' : '0.375rem',
                }}
                animate={{ width: `${String(progress)}%`, opacity: progress }}
                transition={{ duration: isProgressNearComplete ? 0.5 : 0.1 }}
            />
        </motion.div>
    );
};
