import { cn } from '../../utils';
import type { HTMLAttributes } from 'react';
import { forwardRef, type PropsWithChildren } from 'react';

function CustomErrorRoot({ children }: PropsWithChildren) {
    return (
        <div className="fixed inset-0 flex h-screen items-center justify-center">
            <div className="container max-lg:px-4">
                <div className="flex flex-1 flex-col items-center space-x-0 space-y-6">
                    {children}
                </div>
            </div>
        </div>
    );
}

const CustomErrorHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    (props, ref) => <div ref={ref} {...props} />,
);
CustomErrorHeader.displayName = 'CustomErrorHeader';

const CustomErrorContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return <div ref={ref} className={cn('space-y-2 text-center', className)} {...props} />;
    },
);
CustomErrorContent.displayName = 'CustomErrorContent';

const CustomErrorFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn('flex items-center space-x-2 space-y-0', className)}
                {...props}
            />
        );
    },
);
CustomErrorFooter.displayName = 'CustomErrorFooter';

export { CustomErrorRoot, CustomErrorHeader, CustomErrorContent, CustomErrorFooter };
