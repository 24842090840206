import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgWhatsNewIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="m18.683 9.45-1.55-1.767.217-2.341a.836.836 0 0 0-.642-.892l-2.3-.525-1.2-2.025a.83.83 0 0 0-1.041-.342L10 2.5l-2.158-.925a.828.828 0 0 0-1.042.342L5.592 3.933 3.3 4.45a.83.83 0 0 0-.65.892l.217 2.333-1.55 1.775a.836.836 0 0 0 0 1.1l1.55 1.767-.217 2.35c-.042.416.242.8.642.891l2.3.525 1.2 2.017a.838.838 0 0 0 1.05.342L10 17.5l2.158.925a.827.827 0 0 0 1.042-.342l1.2-2.025 2.3-.525a.829.829 0 0 0 .642-.891l-.217-2.342 1.55-1.767a.805.805 0 0 0 .008-1.083Zm-7.85 4.717H9.167V12.5h1.666v1.667ZM10 10.833A.836.836 0 0 1 9.167 10V6.667c0-.459.375-.834.833-.834.458 0 .833.375.833.834V10a.836.836 0 0 1-.833.833Z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgWhatsNewIcon);
export default ForwardRef;
