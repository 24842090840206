interface FileType {
    id: number;
    fileName: string;
    filePath: string;
    fileType: string;
    fileSize: number;
    fileUrl: string | null;
    expires: string | null;
    size: string | null;
    attachmentUrl: string;
}

interface UploadSuccess {
    error: boolean;
    url: string;
    filename: string;
}

interface APIResponse {
    data: {
        signedUrl?: string;
        filename?: string;
        url?: string;
        error?: string;
    };
}

async function fetcher<T>(url: string, options: RequestInit): Promise<T> {
    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json() as Promise<T>;
}

async function fileUploadToS3(
    file: File,
    url: string,
    setUploadProgress?: (percentage: number) => void,
): Promise<UploadSuccess> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                setUploadProgress && setUploadProgress(percentage);
            }
        });

        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve({ error: false, url, filename: file.name });
            } else {
                reject({ error: true } as unknown as Error);
            }
        };

        xhr.onerror = () => {
            reject({ error: true } as unknown as Error);
        };

        xhr.open('PUT', url, true);
        xhr.send(file);
    });
}

async function fileUpload(
    api: string,
    file: File,
    setUploadProgress?: (progress: number) => void,
): Promise<{ data: FileType | null; failed: boolean }> {
    try {
        const { data } = await fetcher<APIResponse>(`${api}/upload`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ filename: file.name, filetype: file.type }),
        });

        if (!data.signedUrl) {
            throw new Error('No signed URL returned from the server.');
        }

        const s3response = await fileUploadToS3(file, data.signedUrl, setUploadProgress);

        if (s3response.error) {
            return { data: null, failed: true };
        }

        const uploadSuccessResponse = await fetcher<{ data: FileType }>(`${api}/upload/success`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ filename: s3response.filename, url: s3response.url }),
        });

        return { data: uploadSuccessResponse.data, failed: false };
    } catch (error) {
        console.error(error); // Log or handle the error as needed
        return { data: null, failed: true };
    }
}

export { fileUpload };
