import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgQuoteRightIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M27.597.5v6.136c0 1.743-.341 3.523-1.023 5.341a23.39 23.39 0 0 1-2.699 5.142c-1.117 1.61-2.33 2.955-3.636 4.034L14.898 18a28.086 28.086 0 0 0 2.613-5.227c.663-1.819.995-3.845.995-6.08V.5h9.09ZM13.25.5v6.136c0 1.743-.34 3.523-1.023 5.341a23.386 23.386 0 0 1-2.699 5.142c-1.117 1.61-2.33 2.955-3.636 4.034L.552 18a28.08 28.08 0 0 0 2.613-5.227c.663-1.819.994-3.845.994-6.08V.5h9.091Z"
        fill="#E3E3E3"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgQuoteRightIcon);
export default ForwardRef;
