import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgAvailability(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 24 24"
            ref={ref}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m-3 7.53a.754.754 0 0 0-1.06 0l-4.35 4.35L9 14.29a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06l1.94 1.94c.39.39 1.02.39 1.41 0l4.7-4.7c.3-.29.3-.77.01-1.06M6 20h12c.55 0 1-.45 1-1V9H5v10c0 .55.45 1 1 1"
                clipRule="evenodd"
            />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgAvailability);
export default ForwardRef;
