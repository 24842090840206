import 'react-day-picker/style.css';

import { ArrowDropDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@in2event/icons';
import { cn } from '../../utils';
import { DayPicker, getDefaultClassNames } from 'react-day-picker';
import type { DayPickerProps } from 'react-day-picker';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
    className,
    classNames,
    showOutsideDays = true,
    mode = 'single',
    required = false,
    ...props
}: DayPickerProps) {
    const defaultClassNames = getDefaultClassNames();

    return (
        <DayPicker
            {...props}
            weekStartsOn={1}
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            classNames={{
                root: `${defaultClassNames.root} shadow-lg p-4 rounded-lg`,
                chevron: `${defaultClassNames.chevron} fill-neutral-60 size-6`,
                day: 'p-0',
                day_button: cn(
                    'h-9 w-8 rounded-md p-0 text-sm font-medium',
                    'hover:cursor-pointer hover:bg-primary-200 hover:text-primary-600 disabled:bg-transparent disabled:text-neutral-200/50',
                ),
                today: cn(
                    'underline-primary-600 font-semibold text-primary-600 underline underline-offset-[3px]',
                ),
                nav: cn(`flex items-center space-x-1`, mode === 'single' && 'relative'),
                button_previous: 'absolute left-2 top-2',
                button_next: 'absolute right-2 top-2',
                dropdowns: 'flex flex-1 gap-2 items-center justify-center',
                months:
                    mode === 'range'
                        ? 'flex flex-col sm:flex-row space-y-4 sm:space-y-0 relative space-x-4'
                        : undefined,
                month_grid: 'w-full border-collapse space-y-1',
                caption_label:
                    'text-center text-sm font-medium flex items-center justify-center w-full',
                weekday:
                    'text-neutral-200/50 uppercase rounded-md w-9 font-semibold text-[11px] leading-4',
                month: mode === 'range' ? 'space-y-3' : 'space-y-5',
                outside: 'text-neutral-200/50',
                hidden: 'invisible',

                disabled: 'text-[#DFE1E6]',
                ...classNames,
            }}
            modifiers={{
                // @ts-ignore
                selection: props.selected,
                // @ts-ignore
                from: props.selected?.from,
                // @ts-ignore
                to: props.selected?.to,
            }}
            modifiersClassNames={{
                selection: cn(
                    'font-semibold text-white [&>button:first-child]:bg-neutral-500 disabled:[&>button:first-child]:text-white',
                    mode === 'range' && '[&>button:first-child]:rounded-none',
                ),
                from: '[&>button:first-child]:rounded-l-md [&>button:first-child]:rounded-r-none',
                to: '[&>button:first-child]:rounded-r-md [&>button:first-child]:rounded-l-none',
            }}
            formatters={{
                formatWeekdayName: (weekday) =>
                    weekday.toLocaleString('en-US', { weekday: 'short' }),
            }}
            components={{
                Chevron: (props) => {
                    if (props.orientation === 'left') {
                        return <ChevronLeftIcon {...props} />;
                    }
                    return <ChevronRightIcon {...props} />;
                },
                Dropdown: (props) => {
                    const { options, className, classNames, ...selectProps } = props;

                    return (
                        <div className="relative flex items-center">
                            <select
                                {...selectProps}
                                className={cn(
                                    'z-10 flex-1 appearance-none bg-transparent pr-5 text-sm font-bold leading-4 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus:ring-offset-transparent',
                                    className,
                                )}
                            >
                                {options?.map(({ value, label, disabled }) => (
                                    <option key={value} value={value} disabled={disabled}>
                                        {label}
                                    </option>
                                ))}
                            </select>
                            <ArrowDropDownIcon className="pointer-events-none absolute right-0 size-5" />
                        </div>
                    );
                },
            }}
        />
    );
}
Calendar.displayName = 'Calendar';

export { Calendar };
export type { DateRange, Matcher } from 'react-day-picker';
