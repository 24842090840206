'use client';

import { CheckCircleSymbol, AlertIcon, CloseIcon } from '@in2event/icons';
import { useTheme } from 'next-themes';
import { ExternalToast, Toaster, toast } from 'sonner';
import { Button } from '../button';
import { cn } from '../../utils';

type ToasterProps = React.ComponentProps<typeof Toaster>;

const Sonner = ({ ...props }: ToasterProps) => {
    const { theme = 'system' } = useTheme();

    return (
        <Toaster
            theme={theme as ToasterProps['theme']}
            icons={{
                error: <AlertIcon className="size-6 fill-warning-600" />,
                success: <CheckCircleSymbol className="size-6 fill-affirmative-600" />,
            }}
            toastOptions={{
                unstyled: true,
                classNames: {
                    toast: cn(
                        'flex items-center gap-2 rounded-lg border border-neutral-200 bg-neutral-200 px-3 py-2 text-white shadow-elevation',
                    ),
                },
            }}
            {...props}
        />
    );
};

// Success toast
Sonner.success = (message: React.ReactNode, data?: ExternalToast | undefined) =>
    toast.custom(
        (t) => {
            return (
                <div className="flex h-10 flex-nowrap items-center justify-between space-x-4 space-y-0">
                    <div className="flex flex-nowrap items-center">
                        <CheckCircleSymbol className="size-6 fill-affirmative-600" />
                        <div className="ml-2 text-sm font-semibold">{message}</div>
                    </div>
                    <Button
                        className="text-white"
                        variant="subtle"
                        size="icon"
                        onClick={() => toast.dismiss(t)}
                    >
                        <CloseIcon className="size-6" />
                    </Button>
                </div>
            );
        },
        {
            ...data,
        },
    );

// Error toast
Sonner.error = (message: React.ReactNode, data?: ExternalToast | undefined) =>
    toast.custom(
        (t) => {
            return (
                <div className="flex h-10 flex-nowrap items-center justify-between space-x-4 space-y-0">
                    <div className="flex flex-nowrap items-center">
                        <AlertIcon className="size-6 fill-warning-600" />
                        <div className="ml-2 text-sm font-semibold">{message}</div>
                    </div>
                    <Button
                        className="text-white"
                        variant="subtle"
                        size="icon"
                        onClick={() => toast.dismiss(t)}
                    >
                        <CloseIcon className="size-6" />
                    </Button>
                </div>
            );
        },
        {
            ...data,
        },
    );

Sonner.custom = toast.custom;
Sonner.dismiss = toast.dismiss;

export { Sonner };
