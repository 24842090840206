import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgPersonCancelIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 22 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <path
                d="m18.5 7.399-1.4 1.375a.977.977 0 0 1-.688.287.93.93 0 0 1-.712-.287.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7l1.375-1.4L15.7 4.599a.933.933 0 0 1-.275-.688c0-.275.092-.512.275-.712.2-.2.438-.3.713-.3.274 0 .512.1.712.3L18.5 4.574l1.375-1.4c.183-.2.413-.3.688-.3.274 0 .512.1.712.3.2.2.3.437.3.712 0 .275-.1.513-.3.713L19.9 5.974l1.4 1.4c.2.2.296.433.287.7-.008.267-.112.5-.312.7-.2.183-.433.28-.7.287a.916.916 0 0 1-.7-.287L18.5 7.399ZM8 8.474c-1.1 0-2.042-.392-2.825-1.175C4.392 6.516 4 5.574 4 4.474s.392-2.042 1.175-2.825C5.958.866 6.9.474 8 .474s2.042.392 2.825 1.175C11.608 2.432 12 3.374 12 4.474s-.392 2.042-1.175 2.825C10.042 8.082 9.1 8.474 8 8.474Zm-8 6v-.8c0-.567.146-1.088.438-1.563.291-.475.679-.837 1.162-1.087A14.851 14.851 0 0 1 4.75 9.86 13.758 13.758 0 0 1 8 9.474c1.1 0 2.183.13 3.25.387a14.85 14.85 0 0 1 3.15 1.163c.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563v.8c0 .55-.196 1.02-.588 1.412a1.926 1.926 0 0 1-1.412.588H2c-.55 0-1.02-.196-1.413-.588A1.926 1.926 0 0 1 0 14.474Z"
                fill="currentColor"
            />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgPersonCancelIcon);
export default ForwardRef;
