import { DialogDismiss, DialogOverlay, DialogPortal, DialogTitle, DialogClose } from '../dialog';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cn } from '../../utils';
import { Children, forwardRef } from 'react';

type DialogContentElement = React.ElementRef<typeof DialogPrimitive.Content>;
type DialogContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>;
interface SearchDialogProps extends DialogContentProps {}

const SearchDialogContent = forwardRef<DialogContentElement, SearchDialogProps>(
    ({ children, className, title, ...props }: SearchDialogProps, forwardedRef) => {
        const [bodyChild, footerChild] = Children.toArray(children);

        return (
            <DialogPortal>
                <DialogOverlay />
                <DialogPrimitive.Content
                    ref={forwardedRef}
                    className={cn(
                        'fixed left-1/2 top-1/2 z-50 grid w-full max-w-sm -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-lg bg-white shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
                        className,
                    )}
                    {...props}
                >
                    <div className="box-border overflow-hidden">
                        <header className="flex h-16 items-center border-b-0 px-5">
                            <DialogTitle>{title}</DialogTitle>
                        </header>
                        <main className="px-6 pb-6 pt-2">{bodyChild}</main>
                        <footer className="flex h-18 justify-end gap-2 px-5">{footerChild}</footer>
                    </div>

                    <DialogDismiss />
                </DialogPrimitive.Content>
            </DialogPortal>
        );
    },
);
SearchDialogContent.displayName = DialogPrimitive.Content.displayName;

export const SearchDialog = {
    Root: DialogPrimitive.Root,
    Trigger: DialogPrimitive.Trigger,
    Content: SearchDialogContent,
    Close: DialogClose,
};
