import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgKbcLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M4.129.5h23.742c2 0 3.629 1.64 3.629 3.674v15.652c0 2.034-1.63 3.674-3.629 3.674H4.129c-2 0-3.629-1.64-3.629-3.674V4.174C.5 2.14 2.13.5 4.129.5Z"
        fill="#fff"
        stroke="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.084 10.877a2.934 2.934 0 1 0-2.936-2.933 2.934 2.934 0 0 0 2.936 2.933Z"
        fill="#019ACC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.437 11.528a3.897 3.897 0 0 1-2.365-.805 67.667 67.667 0 0 0-5.588.812v1.247h17.033v-2.625s-2.945-.018-6.27.16a3.858 3.858 0 0 1-2.81 1.211Z"
        fill="#019ACC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.504 18.261h-2.02v-4.696h2.02v2.114l1.2-2.114h2.421l-1.773 2.427 1.673 2.27h-2.31l-1.197-1.766-.014 1.765ZM24.484 18.012a5.66 5.66 0 0 1-1.664.25c-1.998 0-3.465-.613-3.465-2.391 0-1.607 1.422-2.306 3.366-2.306.54 0 1.226.065 1.763.25l-.001 1.556c-.398-.26-.796-.421-1.365-.421-.741 0-1.482.393-1.482.964 0 .572.737.963 1.482.963.56 0 .967-.167 1.365-.414v1.549ZM15.476 15.555h1.006c.389 0 .541-.175.541-.445 0-.309-.153-.463-.513-.463h-1.034v.908Zm0 1.751h1.053c.395 0 .53-.182.53-.451 0-.27-.136-.464-.525-.464h-1.058v.915Zm-2.019-3.74h4.082c1.06 0 1.408.46 1.408 1.177 0 .802-.642 1.15-1.302 1.19v.013c.678.06 1.338.145 1.338 1.092 0 .618-.348 1.223-1.514 1.223h-4.012v-4.696Z"
        fill="#003466"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgKbcLogoIcon);
export default ForwardRef;
