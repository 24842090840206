import Text from '../typography/text';

interface ProfileSheetFieldProps {
    label: string;
    value: string | string[] | null | undefined | object;
}

const ProfileSheetField = ({ label, value }: ProfileSheetFieldProps) => {
    const sanitizedValue = value ?? '-';

    const displayValue = () => {
        if (Array.isArray(sanitizedValue)) {
            return sanitizedValue.join(', ');
        }

        if (typeof sanitizedValue === 'object') {
            return '-';
        }

        if (typeof sanitizedValue === 'string') {
            return sanitizedValue;
        }

        if (typeof sanitizedValue === 'string' && sanitizedValue === '') {
            return '-';
        }

        return String(sanitizedValue);
    };

    return (
        <div className="mb-4 flex gap-4">
            <Text variant="card-label" className="w-[112px] shrink-0">
                {label}
            </Text>
            <Text variant="card-value">{displayValue()}</Text>
        </div>
    );
};

export default ProfileSheetField;
