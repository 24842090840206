import DOMPurify from 'dompurify';
import parse, {
    DOMNode,
    Element,
    HTMLReactParserOptions,
    attributesToProps,
    domToReact,
} from 'html-react-parser';
import React, { ReactNode } from 'react';
import Heading from '../typography/heading';

interface SanitizedContentProps {
    htmlContent: string;
}

// Define components outside the main component
const AnchorComponent = ({
    attribs,
    children,
}: {
    attribs: Record<string, string>;
    children: ReactNode;
}) => (
    <a
        {...attributesToProps(attribs)}
        className="text-primary-500 underline"
        target="_blank"
        rel="noopener noreferrer"
    >
        {children}
    </a>
);

const ParagraphComponent = ({
    attribs,
    children,
}: {
    attribs: Record<string, string>;
    children: ReactNode;
}) => (
    <p {...attributesToProps(attribs)} className="mb-4 text-sm text-base-900">
        {children}
    </p>
);

const HeadingComponent = ({
    attribs,
    children,
    as,
}: {
    attribs: Record<string, string>;
    children: ReactNode;
    as?: string;
}) => {
    const Tag = as ?? 'h4';

    return (
        <Heading {...attributesToProps(attribs)} as={Tag as 'h4'} variant="title-3">
            {children}
        </Heading>
    );
};

const ListComponent = ({
    attribs,
    children,
    ordered,
}: {
    attribs: Record<string, string>;
    children: ReactNode;
    ordered?: boolean;
}) => {
    const Component = ordered ? 'ol' : 'ul';
    const className = ordered
        ? 'text-base-900 list-decimal space-y-6 pl-4 text-sm'
        : 'text-base-900 list-disc space-y-2 pl-4 text-sm';
    return (
        <Component {...attributesToProps(attribs)} className={className}>
            {children}
        </Component>
    );
};

const SanitizedHtmlContent = ({ htmlContent }: SanitizedContentProps) => {
    const sanitizeHtml = DOMPurify.sanitize(htmlContent);

    const options: HTMLReactParserOptions = {
        replace: (domNode) => {
            if (domNode instanceof Element) {
                const { attribs, children } = domNode;
                switch (domNode.name) {
                    case 'a':
                        return (
                            <AnchorComponent attribs={attribs}>
                                {domToReact(children as DOMNode[], options)}
                            </AnchorComponent>
                        );
                    case 'p':
                        return (
                            <ParagraphComponent attribs={attribs}>
                                {domToReact(children as DOMNode[], options)}
                            </ParagraphComponent>
                        );
                    case 'ul':
                        return (
                            <ListComponent attribs={attribs}>
                                {domToReact(children as DOMNode[], options)}
                            </ListComponent>
                        );
                    case 'ol':
                        return (
                            <ListComponent attribs={attribs} ordered>
                                {domToReact(children as DOMNode[], options)}
                            </ListComponent>
                        );
                    case 'h4':
                        return (
                            <HeadingComponent attribs={attribs}>
                                {domToReact(children as DOMNode[], options)}
                            </HeadingComponent>
                        );
                    default:
                        break;
                }
            }
            return domNode;
        },
    };

    return <>{parse(sanitizeHtml, options)}</>;
};

export { SanitizedHtmlContent };
