import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgPlayIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      ref={ref}
      {...props}
    >
      <path d="M8 6.82v10.36c0 .79.87 1.27 1.54.84l8.14-5.18a1 1 0 0 0 0-1.69L9.54 5.98A.998.998 0 0 0 8 6.82z" />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgPlayIcon);
export default ForwardRef;
