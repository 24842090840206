import { cn } from '../../utils';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

const Switch = forwardRef<
    ElementRef<typeof SwitchPrimitive.Root>,
    ComponentPropsWithoutRef<typeof SwitchPrimitive.Root>
>(({ className, ...props }, forwardedRef) => {
    return (
        <SwitchPrimitive.Root
            ref={forwardedRef}
            className={cn(
                'peer inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent bg-neutral-900 shadow-sm transition-colors focus-visible:outline-none disabled:cursor-not-allowed data-[state=checked]:bg-primary-600 data-[state=unchecked]:bg-neutral-900 data-[state=checked]:disabled:bg-neutral-1900 data-[state=checked]:disabled:opacity-50',
                className,
            )}
            {...props}
        >
            <SwitchPrimitive.Thumb className="pointer-events-none block size-4 rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0" />
        </SwitchPrimitive.Root>
    );
});

Switch.displayName = 'Switch';

export { Switch };
