'use client';

import React, { ComponentPropsWithoutRef, use, useEffect } from 'react';
import { CloseIcon, SearchIcon } from '@in2event/icons';
import { useState } from 'react';
import { useDebounce } from 'react-use';
import { InputText } from '../input-text';
import { Button } from '../button';
import { cn } from '../../utils';

interface DataTableSearchProps
    extends Omit<ComponentPropsWithoutRef<typeof InputText>, 'value' | 'onChange'> {
    ref?: React.RefObject<HTMLInputElement>;
    searchValue: string;
    onSearch: (value: string) => void;
}

export const DataTableSearch = ({ searchValue, onSearch, ...props }: DataTableSearchProps) => {
    const [val, setVal] = useState('');

    const [] = useDebounce(
        () => {
            onSearch(val);
        },
        200,
        [val],
    );

    useEffect(() => {
        setVal(searchValue);
    }, [searchValue]);

    return (
        <div className="relative flex basis-full items-center md:basis-auto">
            <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-2.5">
                <SearchIcon className="size-5 text-neutral-60" />
            </div>
            <InputText
                variant="compact"
                {...props}
                type="text"
                value={val}
                className={cn('pl-9 pr-4', props.className)}
                onChange={({ target }) => {
                    setVal(target.value);
                }}
            />
            <Button
                onClick={() => {
                    setVal('');
                    onSearch('');
                }}
                size="icon"
                variant="subtle"
                className={cn('absolute right-2 size-6 p-1', !val && 'hidden')}
            >
                <CloseIcon className="size-4" />
            </Button>
        </div>
    );
};
