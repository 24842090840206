import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgLogoNew(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M24.354 48c-3.723 0-7.366-.483-10.84-1.433-5.82-1.597-10.444-6.15-12.06-11.886A39.4 39.4 0 0 1 0 23.998c0-3.66.49-7.255 1.458-10.675 1.616-5.74 6.236-10.293 12.06-11.89A41.046 41.046 0 0 1 24.35 0c3.185 0 6.342.358 9.377 1.063 6.935 1.616 12.258 6.862 13.894 13.693a40.147 40.147 0 0 1 1.087 9.238c0 3.669-.49 7.26-1.455 10.683-1.62 5.736-6.24 10.293-12.06 11.886A40.945 40.945 0 0 1 24.353 48ZM24.35 2.98a37.88 37.88 0 0 0-10.021 1.323C9.519 5.62 5.706 9.382 4.37 14.118a36.356 36.356 0 0 0-1.343 9.88c0 3.396.45 6.722 1.343 9.88 1.336 4.736 5.153 8.498 9.959 9.815 6.41 1.76 13.629 1.76 20.054 0 4.805-1.317 8.622-5.079 9.958-9.814a36.359 36.359 0 0 0 1.344-9.88 36.84 36.84 0 0 0-1.004-8.553C43.306 9.716 38.844 5.32 33.03 3.965a38.298 38.298 0 0 0-8.681-.986Z"
        fill="#000"
      />
      <path
        d="M31.968 29.785H16.74v4.09h15.23v-4.09ZM24.354 12.712c-3.845 0-7.03 2.823-7.544 6.472h4.236a3.47 3.47 0 0 1 3.312-2.39c1.916 0 3.47 1.537 3.47 3.418 0 1.527-1.02 2.824-2.427 3.264v4.175c3.707-.502 6.567-3.645 6.567-7.435 0-4.14-3.414-7.504-7.614-7.504Z"
        fill="#000"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgLogoNew);
export default ForwardRef;
