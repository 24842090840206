import React, { HTMLAttributes } from 'react';
import { cn } from '../../utils';

interface DefaultLayoutProps extends HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

export const DefaultLayout = ({ children, className, ...props }: DefaultLayoutProps) => {
    return (
        <div className={cn('px-4 md:px-9', className)} {...props}>
            {children}
        </div>
    );
};
