'use client';

import { useEffect, useState } from 'react';
import { DayPicker, type DateRange, type Matcher } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { cn } from '../../utils';
import { differenceInDays } from 'date-fns';
import { buttonVariants } from '../button';
import { DateRangeIcon } from '@in2event/icons';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import { Label } from '../label';
import { InputText } from '../input-text';
import { format } from 'date-fns';
import { PopoverAnchor } from '@radix-ui/react-popover';
import { startOfDay } from 'date-fns';

interface CheckInCheckOutProps {
    selected: DateRange | undefined;
    selectedRanges: DateRange[];
    startMonth?: Date;
    endMonth?: Date;
    defaultMonth?: Date;
    fromLabel?: string;
    toLabel?: string;
    onChange: (date: DateRange | undefined) => void;
    disabled?: Matcher | Matcher[] | undefined;
    unavailableDates?: Date[];
}

const CheckInCheckOut = ({
    selected,
    selectedRanges,
    startMonth,
    endMonth,
    defaultMonth,
    fromLabel = 'Check-In',
    toLabel = 'Check-Out',
    onChange,
    disabled,
    unavailableDates,
}: CheckInCheckOutProps) => {
    const [currentRange, setCurrentRange] = useState<DateRange | undefined>(selected);
    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const isMobile = typeof window !== 'undefined' && window.innerWidth <= 600;

    // Helper to get all dates from selected ranges for disabling
    const getAllDisabledDates = (): Date[] => {
        const disabledDates: Date[] = [...(unavailableDates ?? [])];
        // Add all dates from selected ranges to disabledDates, excluding the checkout date
        selectedRanges.forEach((range) => {
            if (range.from && range.to) {
                for (let d = new Date(range.from); d < range.to; d.setDate(d.getDate() + 1)) {
                    disabledDates.push(new Date(d));
                }
            }
        });
        return disabledDates;
    };

    // Function to check if a date falls on a disabled day
    const isDateDisabled = (date: Date): boolean => {
        // Normalize the date to start of day to compare
        const normalizedDate = startOfDay(date);
        return getAllDisabledDates().some(
            (disabledDate) => startOfDay(disabledDate).getTime() === normalizedDate.getTime(),
        );
    };

    // Check if the selected range is valid (does not include disabled dates)
    const isRangeValid = (range: DateRange): boolean => {
        if (!range.from || !range.to) return false;
        // Normalize the dates to start of day to compare
        const from = startOfDay(range.from);
        const to = startOfDay(range.to);
        for (let d = from; d <= to; d.setDate(d.getDate() + 1)) {
            if (isDateDisabled(d)) return false;
        }
        return true;
    };

    // Check how many disabled days are between the selected range
    const disabledDaysBetweenRange = (range: DateRange): Date[] => {
        const disabledDays = getAllDisabledDates();
        return disabledDays.filter((d) => {
            return (
                range.from &&
                range.to &&
                startOfDay(d) > startOfDay(range.from) &&
                startOfDay(d) < startOfDay(range.to)
            );
        });
    };

    const dateDiff =
        currentRange?.from && currentRange.to
            ? differenceInDays(currentRange.to, currentRange.from)
            : 0;

    const modifiers = {
        unavailable: getAllDisabledDates(),
    };
    const modifiersClassNames = {
        unavailable: 'text-[#DFE1E6]',
    };

    const handleRangeSelection = (range: DateRange | undefined) => {
        if (!range?.from || !range?.to) {
            setCurrentRange(range);
            return;
        }

        const isSameDay = range.from.getTime() === range.to.getTime();
        const isValidRange = isRangeValid(range);
        const previousDay = getPreviousDay(range.to);
        const isPreviousDayDisabled = isDateDisabled(previousDay);
        const disabledDays = disabledDaysBetweenRange(range);

        if (!isValidRange) {
            handleInvalidRange(range, disabledDays, isPreviousDayDisabled);
            return;
        }

        handleValidRange(range, isSameDay);
    };

    // Helper function to get the previous day of a given date
    const getPreviousDay = (date: Date): Date => {
        const previousDay = new Date(date);
        previousDay.setDate(previousDay.getDate() - 1);
        return previousDay;
    };

    // Handle the case when the selected range is invalid
    const handleInvalidRange = (
        range: DateRange,
        disabledDays: Date[],
        isPreviousDayDisabled: boolean,
    ) => {
        const fromIsDisabled = isDateDisabled(range.from!);

        // If the from date is disabled or there are already 2 disabled days in the range, do nothing
        if (fromIsDisabled || disabledDays.length >= 2) {
            return;
        }

        // If the previous day is not disabled, set the current range
        // This could happen when the user selects a range where check-out date is disabled
        if (!isPreviousDayDisabled) {
            setCurrentRange(range);
        }
    };

    // Handle the case when the selected range is valid
    const handleValidRange = (range: DateRange, isSameDay: boolean) => {
        if (isSameDay) {
            setCurrentRange({ from: range.from, to: undefined });
        } else {
            setCurrentRange(range);
        }
    };

    useEffect(() => {
        onChange(currentRange);
    }, [currentRange]);

    return (
        <Popover open={isPopperOpen} onOpenChange={setIsPopperOpen}>
            <PopoverTrigger className="relative flex w-full cursor-pointer flex-col gap-2 gap-y-5 md:flex-row">
                <PopoverAnchor className="absolute inset-0 z-10 w-full" />
                <div className="w-full md:w-1/2">
                    <Label className="mb-2 flex text-left">{fromLabel}</Label>
                    <div className="relative">
                        <InputText
                            name="date-from"
                            value={
                                currentRange?.from
                                    ? format(currentRange.from, 'dd-MM-yyyy')
                                    : 'DD-MM-YYYY'
                            }
                            disabled
                            className={cn('w-full', currentRange?.to && 'disabled:text-base-900')}
                        />
                        <div className="absolute inset-y-0 right-2 flex items-center">
                            <DateRangeIcon className="size-5 shrink-0 text-neutral-750" />
                        </div>
                    </div>
                </div>

                <div className="w-full md:w-1/2">
                    <Label className="mb-2 flex text-left">{toLabel}</Label>
                    <div className="relative">
                        <InputText
                            name="date-to"
                            value={
                                currentRange?.to
                                    ? format(currentRange.to, 'dd-MM-yyyy')
                                    : 'DD-MM-YYYY'
                            }
                            disabled
                            className={cn('w-full', currentRange?.to && 'disabled:text-base-900')}
                        />
                        <div className="absolute inset-y-0 right-2 flex items-center">
                            <DateRangeIcon className="size-5 shrink-0 text-neutral-750" />
                        </div>
                    </div>
                </div>
            </PopoverTrigger>

            <PopoverContent
                align="start"
                sideOffset={isMobile ? -250 : 4}
                className="w-fit"
                onPointerDownOutside={() => setIsPopperOpen(false)}
            >
                <DayPicker
                    mode="range"
                    selected={currentRange}
                    onSelect={handleRangeSelection}
                    numberOfMonths={2}
                    defaultMonth={defaultMonth}
                    disabled={[
                        ...(disabled ? (Array.isArray(disabled) ? disabled : [disabled]) : []),
                    ]}
                    modifiers={modifiers}
                    modifiersClassNames={modifiersClassNames}
                    startMonth={startMonth}
                    endMonth={endMonth}
                    showOutsideDays
                    classNames={{
                        nav: 'absolute inset-x-0 top-0 z-20 flex w-full items-center justify-between space-x-2',
                        caption_label: 'text-center text-sm font-medium',
                        button_previous: buttonVariants({
                            size: 'icon',
                            className:
                                'absolute left-2 top-0 p-0 bg-transparent opacity-80 hover:opacity-100',
                        }),
                        button_next: buttonVariants({
                            size: 'icon',
                            className:
                                'absolute right-2 top-0 p-0 bg-transparent opacity-80 hover:opacity-100',
                        }),
                        today: 'underline-primary-600 font-semibold text-primary-600 underline underline-offset-[3px]',
                        dropdowns: 'flex w-full gap-2',
                        months: 'flex flex-col md:flex-row w-full h-fit gap-5 relative',
                        month: 'flex flex-col w-full space-y-5',
                        month_caption: 'flex justify-center pt-1.5 relative items-center',
                        month_grid: 'w-full border-collapse',
                        weekdays: 'flex justify-between mt-2',
                        weekday:
                            'text-neutral-200/50 uppercase rounded-md w-9 font-semibold text-[11px] leading-4',
                        week: 'flex w-full mt-2',
                        day: 'size-9 text-center text-sm p-0 relative flex items-center justify-center',
                        range_end: cn(
                            'bg-base-900 text-white',
                            dateDiff <= 1 ? 'rounded-r-md' : 'rounded-md',
                        ),
                        range_start: cn(
                            'bg-base-900 text-white',
                            dateDiff <= 1 ? 'rounded-l-md' : 'rounded-md',
                        ),
                        range_middle:
                            'rounded-none bg-transparent [&>button]:bg-neutral-1800 [&>button]:rounded-none [&>button]:w-9 [&>button]:h-8',
                        selected: cn(
                            'rounded-none',
                            dateDiff > 1 && 'rounded-md',
                            currentRange?.from &&
                                !currentRange.to &&
                                'rounded-md bg-base-900 text-white',
                        ),
                    }}
                />
            </PopoverContent>
        </Popover>
    );
};

export { CheckInCheckOut };
