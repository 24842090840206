import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgBancontactLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M4.096.5h23.808C29.886.5 31.5 2.121 31.5 4.129v15.742c0 2.008-1.614 3.629-3.596 3.629H4.096C2.114 23.5.5 21.879.5 19.871V4.129C.5 2.121 2.114.5 4.096.5Z"
        fill="#fff"
        stroke="#E8E8E8"
      />
      <path d="M9 17c3.616 0 5.192-2.589 7-5H4v5h5Z" fill="url(#bancontact-logo-icon_svg__a)" />
      <path d="M23 7c-3.616 0-5.192 2.589-7 5h12V7h-5Z" fill="url(#bancontact-logo-icon_svg__b)" />
      <defs>
        <linearGradient
          id="bancontact-logo-icon_svg__a"
          x1={6.427}
          y1={14.456}
          x2={15.206}
          y2={11.195}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#005AB9" />
          <stop offset={1} stopColor="#1E3764" />
        </linearGradient>
        <linearGradient
          id="bancontact-logo-icon_svg__b"
          x1={16.727}
          y1={12.24}
          x2={26.047}
          y2={9.06}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBA900" />
          <stop offset={1} stopColor="#FFD800" />
        </linearGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = forwardRef(SvgBancontactLogoIcon);
export default ForwardRef;
