import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgLocationIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28 7.006a17.148 17.148 0 0 0-12.453 4.868 16.729 16.729 0 0 0-5.044 12.256c0 14.34 16.226 27.606 16.908 28.152a.93.93 0 0 0 1.197 0c.7-.637 16.89-15.232 16.89-28.152a16.729 16.729 0 0 0-5.045-12.256A17.148 17.148 0 0 0 28 7.006ZM28 31.5a7 7 0 1 1 0-14 7 7 0 0 1 0 14Z"
                fill="currentColor"
            />
            <mask
                id="location-icon_svg__a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={10}
                y={7}
                width={36}
                height={46}
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 7.006a17.148 17.148 0 0 0-12.453 4.868 16.729 16.729 0 0 0-5.044 12.256c0 14.34 16.226 27.606 16.908 28.152a.93.93 0 0 0 1.197 0c.7-.637 16.89-15.232 16.89-28.152a16.729 16.729 0 0 0-5.045-12.256A17.148 17.148 0 0 0 28 7.006ZM28 31.5a7 7 0 1 1 0-14 7 7 0 0 1 0 14Z"
                    fill="currentColor"
                />
            </mask>
        </svg>
    );
}
const ForwardRef = forwardRef(SvgLocationIcon);
export default ForwardRef;
