import { LogoBlueIcon } from '@in2event/icons';
import type { PropsWithChildren } from 'react';

import AuthBackground from '../background';
import { cn } from '../../../utils';

const AuthLayout = ({
    children,
    imageUrl,
}: PropsWithChildren<{
    imageUrl?: string | null;
}>) => (
    <div className={cn('flex h-screen')}>
        <div className="flex flex-1 flex-col p-4">
            <div className="absolute left-9 top-9 mb-12 hidden lg:block">
                <LogoBlueIcon aria-hidden="true" className="size-16" />
            </div>
            <div className="flex flex-1 flex-col items-center max-md:mt-15 md:justify-center">
                <div className="w-full md:max-w-sm">{children}</div>
            </div>
        </div>
        <div className="relative hidden flex-1 bg-primary-600 lg:flex">
            {imageUrl ? <AuthBackground imageUrl={imageUrl} /> : null}
        </div>
    </div>
);

export default AuthLayout;
