import { CellContext } from '@tanstack/react-table';
import { IndeterminateCheckbox } from '../../checkbox';

export const DataTableCellSelect = <T,>({ row }: CellContext<T, unknown>) => (
    <IndeterminateCheckbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
        className="mt-1.5 max-md:ml-1"
    />
);
