import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgPaypalLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M27.904 0H4.096C1.834 0 0 1.849 0 4.129v15.742C0 22.151 1.834 24 4.096 24h23.808C30.166 24 32 22.151 32 19.871V4.129C32 1.849 30.166 0 27.904 0Z"
        fill="#0071C0"
      />
      <path
        d="M20.244 8.87a6.72 6.72 0 0 1-.037.22c-.475 2.538-2.1 3.414-4.176 3.414h-1.057a.52.52 0 0 0-.507.453l-.54 3.57-.154 1.012a.278.278 0 0 0 .267.326h1.874c.222 0 .41-.168.446-.396l.018-.099.353-2.33.022-.128a.457.457 0 0 1 .446-.397h.28c1.816 0 3.238-.767 3.653-2.987.174-.927.084-1.702-.375-2.246a1.803 1.803 0 0 0-.513-.412Z"
        fill="#99C6E5"
      />
      <path
        d="M19.744 8.664a3.638 3.638 0 0 0-.462-.107 5.644 5.644 0 0 0-.931-.07h-2.823a.434.434 0 0 0-.195.045.467.467 0 0 0-.25.35l-.6 3.959-.018.116a.52.52 0 0 1 .507-.453h1.057c2.075 0 3.7-.877 4.175-3.414.015-.075.027-.149.037-.22a2.48 2.48 0 0 0-.497-.206Z"
        fill="#CCE2F2"
      />
      <path
        d="M15.082 8.883a.465.465 0 0 1 .25-.35.435.435 0 0 1 .195-.046h2.824c.334 0 .646.023.931.07a4.132 4.132 0 0 1 .462.107l.107.035c.14.048.27.105.39.171.142-.938 0-1.576-.488-2.154-.537-.637-1.507-.91-2.747-.91h-3.603a.522.522 0 0 0-.508.453l-1.5 9.896a.318.318 0 0 0 .305.372h2.224l.558-3.686.6-3.958Z"
        fill="#fff"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgPaypalLogoIcon);
export default ForwardRef;
