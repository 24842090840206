import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgIdealLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M27.871 0H4.129C1.849 0 0 1.869 0 4.174v15.652C0 22.131 1.849 24 4.129 24h23.742C30.151 24 32 22.131 32 19.826V4.174C32 1.869 30.151 0 27.871 0Z"
        fill="#E664A5"
      />
      <path
        d="M8.5 6.387v11.258a.97.97 0 0 0 .968.968h6.742c5.096 0 7.29-2.84 7.29-6.613 0-3.775-2.194-6.613-7.29-6.613H9.5c-.548.032-1 .484-1 1Z"
        fill="#fff"
      />
      <path
        d="M13.016 8.193v8.323h3.612c3.29 0 4.71-1.87 4.71-4.484 0-2.516-1.42-4.451-4.71-4.451h-3c-.322-.033-.612.258-.612.612Z"
        fill="#C06"
      />
      <path
        d="M16.212 17.742h-5.839a.936.936 0 0 1-.935-.936V7.258c0-.516.419-.936.935-.936h5.839c5.548 0 6.387 3.58 6.387 5.71 0 3.677-2.258 5.71-6.387 5.71ZM10.373 6.645a.613.613 0 0 0-.613.613v9.548c0 .355.29.613.613.613h5.839c3.903 0 6.064-1.935 6.064-5.42 0-4.677-3.806-5.386-6.064-5.386l-5.839.032Z"
        fill="#171717"
      />
      <path
        d="M14.305 10.903c.129 0 .258.033.354.065a.763.763 0 0 1 .29.193l.194.29c.033.13.065.259.065.42 0 .161-.032.29-.065.387-.032.13-.097.226-.161.323s-.161.161-.29.226c-.097.064-.258.064-.387.064h-.871v-2l.87.032Zm-.033 1.613c.065 0 .13 0 .194-.032s.097-.065.161-.097c.032-.064.097-.097.097-.193.032-.065.032-.162.032-.29 0-.097 0-.194-.032-.259-.032-.064-.065-.161-.097-.193-.032-.065-.097-.097-.161-.13-.065-.032-.161-.032-.258-.032h-.323v1.259l.387-.033ZM16.985 10.903v.355H15.92v.452h.968v.322h-.968v.484h1.065v.355h-1.516v-2l1.516.032ZM18.468 10.903l.742 2h-.452l-.16-.451h-.743l-.161.451h-.452l.742-2h.484Zm.032 1.226-.258-.742-.258.742h.516ZM19.92 10.903v1.613h.968v.355h-1.42v-2c.033.032.452.032.452.032Z"
        fill="#fff"
      />
      <path
        d="M11.372 12.806a.903.903 0 1 0 0-1.806.903.903 0 0 0 0 1.806ZM12.082 16.516a1.377 1.377 0 0 1-1.387-1.387v-1.097c0-.387.323-.71.71-.71.387 0 .71.323.71.71l-.033 2.484Z"
        fill="#171717"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgIdealLogoIcon);
export default ForwardRef;
