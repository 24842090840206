import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgCheckCircleFilledSmallIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <rect width={24} height={24} rx={12} fill="#0057FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m10.758 16.724 6.972-7.117a.954.954 0 0 0 0-1.33.908.908 0 0 0-1.304 0l-6.32 6.45-2.532-2.585a.908.908 0 0 0-1.304 0 .954.954 0 0 0 0 1.332l3.184 3.25a.91.91 0 0 0 1.304 0Z"
        fill="#fff"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgCheckCircleFilledSmallIcon);
export default ForwardRef;
