'use client';

import { cn } from '../../utils';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

const HoverCardRoot = HoverCardPrimitive.Root;
const HoverCardPortal = HoverCardPrimitive.Portal;
const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCardArrow = forwardRef<
    ElementRef<typeof HoverCardPrimitive.Arrow>,
    ComponentPropsWithoutRef<typeof HoverCardPrimitive.Arrow>
>(({ className, ...props }, ref) => (
    <HoverCardPrimitive.Arrow ref={ref} className={cn(className)} {...props} />
));
HoverCardArrow.displayName = HoverCardPrimitive.Arrow.displayName;

const HoverCardContent = forwardRef<
    ElementRef<typeof HoverCardPrimitive.Content>,
    ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>
>(({ className, align = 'center', sideOffset = 5, ...props }, ref) => (
    <HoverCardPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
            'z-50 w-70 rounded-lg bg-white p-4 text-sm text-neutral-250 shadow-elevation outline-none drop-shadow-custom data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            className,
        )}
        {...props}
    />
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

export { HoverCardRoot, HoverCardPortal, HoverCardTrigger, HoverCardContent, HoverCardArrow };
