import { cn } from '../../utils';
import * as AvatarPrimitive from '@radix-ui/react-avatar';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';
import { fileUpload } from './uploader';

const getInitials = (name: string) => {
    return name
        .split(' ')
        .filter((part) => part.length > 0)
        .map((part) => part[0])
        .join('');
};

const getAvatarColor = (name: string) => {
    var hash = 0;
    if (name.length === 0) return hash;

    for (var i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }

    var color = '#';
    for (var x = 0; x < 3; x++) {
        var value = (hash >> (x * 8)) & 255;
        color += ('00' + value.toString(16)).slice(-2);
    }

    return color;
};

enum Variant {
    xxs,
    xs,
    sm,
    md,
    lg,
    xl,
    '2xl',
    '4xl',
}

type Props = {
    src?: string;
    alt?: string;
    variant?: Variant;
    isOnline?: boolean;
    name?: string;
    className?: string;
    delayMs?: number;
    rounded?: boolean;
    fallBackClassName?: string;
};

const AvatarRoot = forwardRef<
    ElementRef<typeof AvatarPrimitive.Root>,
    ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Root
        ref={ref}
        className={cn('relative flex size-10 shrink-0 overflow-hidden rounded', className)}
        {...props}
    />
));
AvatarRoot.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = forwardRef<
    ElementRef<typeof AvatarPrimitive.Image>,
    ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={cn('size-full rounded object-cover', className)}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = forwardRef<
    ElementRef<typeof AvatarPrimitive.Fallback>,
    ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={cn('flex size-full items-center justify-center rounded', className)}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

function Avatar({
    src,
    alt,
    variant = Variant.md,
    isOnline,
    name,
    className,
    delayMs,
    rounded = true,
    fallBackClassName,
}: Props) {
    return (
        <AvatarRoot
            className={cn(
                {
                    [Variant['2xl']]: 'w-20 h-20',
                    [Variant['4xl']]: 'w-28 h-28',
                    [Variant.xl]: 'w-16 h-16',
                    [Variant.lg]: 'w-14 h-14',
                    [Variant.md]: 'h-12 w-12',
                    [Variant.sm]: 'h-10 w-10',
                    [Variant.xs]: 'h-8 w-8',
                    [Variant.xxs]: 'h-6 w-6',
                }[variant],
                {
                    'rounded-full': rounded,
                },
                className,
            )}
        >
            <AvatarImage
                src={src}
                alt={alt}
                className={cn({
                    'rounded-full': rounded,
                })}
            />
            {isOnline && (
                <div className="absolute bottom-0 right-0 size-2 -translate-x-1/2 -translate-y-1/2">
                    <span
                        className={cn('block size-2.5 rounded bg-green-400', {
                            'rounded-full': rounded,
                        })}
                    />
                </div>
            )}
            <AvatarFallback
                style={{
                    background: name ? getAvatarColor(name) : undefined,
                }}
                role="avatar"
                className={cn(
                    'font-semibold uppercase tracking-wider text-white',
                    {
                        'rounded-full': rounded,
                    },
                    {
                        [Variant['2xl']]: 'text-2xl',
                        [Variant['4xl']]: 'text-3xl',
                        [Variant.xl]: 'text-xl',
                        [Variant.lg]: 'text-lg',
                        [Variant.md]: 'text-base',
                        [Variant.sm]: 'text-xs',
                        [Variant.xs]: 'text-xs',
                        [Variant.xxs]: 'text-2xs',
                    }[variant],
                    fallBackClassName,
                )}
                {...(delayMs && { delayMs: delayMs })}
            >
                {name ? getInitials(name).slice(0, 2) : 'AZ'}
            </AvatarFallback>
        </AvatarRoot>
    );
}

Avatar.variant = Variant;
Avatar.uploader = fileUpload;
export { Avatar, AvatarRoot, AvatarImage, AvatarFallback, getAvatarColor };
