'use client';

import { CollapsibleContent, CollapsibleRoot, CollapsibleTrigger } from '../collapsible';
import { ExpandMoreIcon } from '@in2event/icons';
import { PropsWithChildren, useState } from 'react';
import { Skeleton } from '../skeleton';
import { cn } from '../../utils';

const MobileCollapsibleSkeleton = ({ children }: PropsWithChildren) => {
    return (
        <CollapsibleRoot defaultOpen className="border-y bg-white">
            <CollapsibleContent>
                <MobileCollapsibleHeader>
                    <div className="space-y-2">
                        <Skeleton className="h-4 w-20" />
                        <Skeleton className="h-4 w-30" />
                    </div>
                </MobileCollapsibleHeader>

                {children}
            </CollapsibleContent>
            <CollapsibleTrigger
                disabled
                className="flex h-12 w-full items-center gap-x-2 rounded-none px-4 py-[14px] text-sm font-semibold [&_svg]:data-[state=open]:-rotate-180"
            >
                <Skeleton className="h-4 w-20" />
            </CollapsibleTrigger>
        </CollapsibleRoot>
    );
};

interface MobileCollapsibleHeaderProps extends PropsWithChildren {
    isHighlighted?: boolean;
    getTriggerNode: (open: boolean) => React.ReactNode;
    defaultOpen?: boolean;
    className?: string;
}

const MobileCollapsibleRoot = ({
    isHighlighted = false,
    children,
    getTriggerNode,
    defaultOpen = false,
    className,
}: MobileCollapsibleHeaderProps) => {
    const [open, setOpen] = useState(defaultOpen);

    return (
        <CollapsibleRoot
            defaultOpen={defaultOpen}
            className={cn('border-y bg-white', isHighlighted && 'bg-blue-100', className)}
            open={open}
            onOpenChange={setOpen}
        >
            {children}
            <CollapsibleTrigger
                className={cn(
                    'flex h-12 w-full items-center gap-x-2 rounded-none px-4 py-[14px] text-sm font-semibold text-neutral-60',
                    'data-[state=closed]:mt-3 data-[state=closed]:border-t data-[state=closed]:border-t-neutral-10',
                    '[&_svg]:data-[state=open]:-rotate-180',
                )}
            >
                <ExpandMoreIcon className="size-5" />
                {getTriggerNode(open)}
            </CollapsibleTrigger>
        </CollapsibleRoot>
    );
};

const MobileCollapsibleHeader = ({ children }: PropsWithChildren) => {
    return (
        <div className="flex items-start justify-between px-4 pb-base pt-[14px]">{children}</div>
    );
};

export const MobileCollapsible = {
    Root: MobileCollapsibleRoot,
    Header: MobileCollapsibleHeader,
    Content: CollapsibleContent,
    Skeleton: MobileCollapsibleSkeleton,
};
