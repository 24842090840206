import Image, { ImageProps, StaticImageData } from 'next/image';
import { useState } from 'react';

interface ImageWithFallbackProps extends Omit<ImageProps, 'onError' | 'src'> {
    src?: string | null | undefined;
    fallbackSrc: string | StaticImageData;
}

const isStaticImageData = (src: string | StaticImageData): src is StaticImageData => {
    return (src as StaticImageData).src !== undefined;
};

const ImageFallback = ({ src, fallbackSrc, ...props }: ImageWithFallbackProps) => {
    const [currentSrc, setCurrentSrc] = useState(src);
    const [hasError, setHasError] = useState(false);

    const handleError = () => {
        if (!hasError) {
            if (isStaticImageData(fallbackSrc)) {
                setCurrentSrc(fallbackSrc.src);
            } else {
                setCurrentSrc(fallbackSrc);
            }
            // If there hasn't been an error yet
            setHasError(true); // Mark that an error occurred to prevent further attempts
        }
    };

    // Set fallbackSrc as the default src if currentSrc is null or undefined or an empty string
    const defaultSrc = currentSrc?.trim() ? currentSrc : fallbackSrc;

    return <Image {...props} src={defaultSrc} onError={handleError} />;
};

export default ImageFallback;
