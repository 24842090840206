import { SingleAddLayoutContent } from './layout-content';
import { SingleAddLayoutFooter } from './layout-footer';
import { SingleAddLayoutHeader } from './layout-header';
import { SingleAddLayoutRoot } from './layout-root';
import { SingleAddLayoutSkeleton } from './layout-skeleton';

export const SingleAddLayout = {
    Root: SingleAddLayoutRoot,
    Header: SingleAddLayoutHeader,
    Content: SingleAddLayoutContent,
    Footer: SingleAddLayoutFooter,
    Skeleton: SingleAddLayoutSkeleton,
};
