import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgModuleVolunteersIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z"
        fill="#CFF0F5"
      />
      <path
        d="M18.25 21a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM23 19.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm7 .25c0 5.52-4.48 10-10 10s-10-4.48-10-10 4.48-10 10-10 10 4.48 10 10Zm-2 0c0-.78-.12-1.53-.33-2.24-.7.15-1.42.24-2.17.24a10 10 0 0 1-7.76-3.69A10.016 10.016 0 0 1 12 19.86c.01.04 0 .09 0 .14 0 4.41 3.59 8 8 8s8-3.59 8-8Z"
        fill="#068DA6"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgModuleVolunteersIcon);
export default ForwardRef;
