import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgAddPhotoSymbol(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 -960 960 960"
            width="1em"
            ref={ref}
            {...props}
        >
            <path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h409v171h81v81h170v408q0 24-18 42t-42 18H180Zm60-162h480L576-474 449-307l-94-124-115 149Zm460-336v-81h-81v-60h81v-81h60v81h81v60h-81v81h-60Z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgAddPhotoSymbol);
export default ForwardRef;
