import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgCurvyBorderIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 64 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path d="M.75 7.698C11.819 5.801 39.787 2.012 63.11 2.034" stroke="#0057FF" strokeWidth={3} />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgCurvyBorderIcon);
export default ForwardRef;
