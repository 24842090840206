import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgModuleProgramIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Z"
        fill="#E8E5F9"
      />
      <path
        d="M20 22c1.66 0 2.99-1.34 2.99-3l.01-6c0-1.66-1.34-3-3-3s-3 1.34-3 3v6c0 1.66 1.34 3 3 3Zm5.3-3c0 3-2.54 5.1-5.3 5.1S14.7 22 14.7 19H13c0 3.41 2.72 6.23 6 6.72V29h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7Z"
        fill="#4C37BD"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgModuleProgramIcon);
export default ForwardRef;
