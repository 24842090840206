import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context with a generic type T
type NamedContextType<T> = {
    value: T;
    setValue: React.Dispatch<React.SetStateAction<T>>;
};

// Function to create a named context
export function createNamedContext<T>(name: string) {
    const NamedContext = createContext<NamedContextType<T> | undefined>(undefined);

    const NamedProvider: React.FC<{ children: ReactNode; initialValue: T }> = ({
        children,
        initialValue,
    }) => {
        const [value, setValue] = useState<T>(initialValue);

        return (
            <NamedContext.Provider value={{ value, setValue }}>{children}</NamedContext.Provider>
        );
    };

    const useNamedContext = () => {
        const context = useContext(NamedContext);
        if (context === undefined) {
            throw new Error(`use${name}Context must be used within a ${name}Provider`);
        }
        return context;
    };

    return { Provider: NamedProvider, useContext: useNamedContext };
}
