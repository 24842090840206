'use client';

import { HeaderContext } from '@tanstack/react-table';
import { IndeterminateCheckbox } from '../../checkbox';
import { DataTableCellSelect } from '../data-table-cells/data-table-cell-select';
import { ReactNode } from 'react';

export const ColumnHeadersSelect = <T,>({ table }: HeaderContext<T, unknown>) => (
    <IndeterminateCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
    />
);

export const DataTableColumnHeaderSelectMobile = <T,>({
    children,
    isHidden,
    t,
    count,
}: {
    children: ReactNode;
    isHidden: boolean;
    t: (key: string, options?: { count: number }) => string;
    count: number;
}) => {
    if (isHidden) {
        return null;
    }

    return (
        <div className="flex h-[49px] items-center px-4 text-left">
            <div className="w-9">{children}</div>
            <span className="text-sm font-semibold leading-4 text-neutral-60">
                {count > 0
                    ? t('table.row.selection', {
                          count,
                      })
                    : t('table.select.all')}
            </span>
        </div>
    );
};

export const DataTableColumnSelectConfig = <T,>() => ({
    accessorKey: 'select',
    header: ColumnHeadersSelect<T>,
    cell: DataTableCellSelect<T>,
    enableResizing: false,
    size: 30,
});
