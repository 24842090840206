'use client';

import { WarningIcon } from '@in2event/icons';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogDescription,
    AlertDialogTitle,
} from './alert-dialog';
import { Button } from './button';

const UnsavedChangesDialog = ({
    open,
    onOpenChange,
    onConfirm,
    messages,
    isMaxZIndex,
}: {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onConfirm: () => void;
    isMaxZIndex?: boolean;
    messages: {
        title: string;
        description: string;
        cancel: string;
        confirm: string;
    };
}) => {
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle className="inline-flex items-center gap-x-3 text-red-500">
                        <WarningIcon className="size-6 text-red-500" />
                        {messages.title}
                    </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogBody>
                    <AlertDialogDescription>{messages.description}</AlertDialogDescription>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel asChild>
                        <Button type="button" variant="subtle">
                            {messages.cancel}
                        </Button>
                    </AlertDialogCancel>
                    <Button onClick={onConfirm} variant="destructive">
                        {messages.confirm}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default UnsavedChangesDialog;
