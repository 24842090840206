import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgLibraryBookIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.142 5.983 8.796 2.147c-1.006-.537-2.99.5-3.56 1.414C4.98 3.969 5 4.263 5 4.43l.09 8.662c.007.184.236.432.431.554.408.253 6.58 4.157 6.754 4.267a.606.606 0 0 0 .587.023.525.525 0 0 0 .301-.466V8.372a.523.523 0 0 0-.287-.46L6.2 4.138c.075-.15.372-.464.918-.753.576-.305 1.006-.19 1.105-.151 0 0 6.408 3.474 6.604 3.577.195.103.2.119.2.295v8.634c0 .43.431.607.748.607.316 0 .654-.314.654-.607V6.442c0-.19-.11-.364-.287-.459Z"
        fill="#0057FF"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgLibraryBookIcon);
export default ForwardRef;
