import { useCallback } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import { Button } from '../button';

type Props = Omit<DropzoneOptions, 'onDrop'>;

interface FileUploaderProps extends Props {
    title: string;
    onUpload: (files: File[]) => void;
    isLoading?: boolean;
}

function Uploader({ title, onUpload, isLoading, maxFiles = 1, ...props }: FileUploaderProps) {
    const onDrop = useCallback(
        (accepted: File[]) => {
            onUpload(accepted);
        },
        [onUpload],
    );

    const { getRootProps, open, getInputProps } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        onDrop,
        maxFiles,
        multiple: false,
        ...props,
    });

    return (
        <div {...getRootProps()} data-testid="uploader">
            <input {...getInputProps()} />
            <Button className="whitespace-nowrap" onClick={open} loading={isLoading}>
                {title}
            </Button>
        </div>
    );
}

export { Uploader };
