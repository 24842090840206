import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgCredit(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <mask
                id="credit_svg__a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={24}
                height={24}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#credit_svg__a)">
                <path
                    d="M12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Zm-2-6h4c.283 0 .52-.096.713-.287A.968.968 0 0 0 15 15v-1a.968.968 0 0 0-.287-.713A.968.968 0 0 0 14 13a.968.968 0 0 0-.713.287A.968.968 0 0 0 13 14h-2v-4h2c0 .283.096.52.287.713.192.191.43.287.713.287s.52-.096.713-.287A.968.968 0 0 0 15 10V9a.967.967 0 0 0-.287-.713A.968.968 0 0 0 14 8h-4a.968.968 0 0 0-.713.287A.968.968 0 0 0 9 9v6c0 .283.096.52.287.713.192.191.43.287.713.287Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
const ForwardRef = forwardRef(SvgCredit);
export default ForwardRef;
