import { cn } from '../../utils';
import { HTMLAttributes } from 'react';

// Define the props for the root component of the layout footer
type SingleAddLayoutFooterRootProps = HTMLAttributes<HTMLDivElement>;

// Root component of the layout footer
export const SingleAddLayoutFooterRoot = ({
    className,
    ...props
}: SingleAddLayoutFooterRootProps) => {
    return (
        <footer className="sticky z-10 border-t border-base-300 bg-white max-md:shadow-elevation">
            <div
                className="mx-auto flex h-16 max-w-screen-lg items-center justify-end px-4 md:px-6 lg:px-0"
                {...props}
            />
        </footer>
    );
};

// Component for the actions section of the layout footer
export const SingleAddLayoutFooterActions = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => {
    return <div className={cn('flex items-center space-x-5', className)} {...props} />;
};

// Object that contains the root and actions components of the layout footer
export const SingleAddLayoutFooter = {
    Root: SingleAddLayoutFooterRoot,
    Actions: SingleAddLayoutFooterActions,
};
