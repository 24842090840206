import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgCreditCardLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <mask
        id="credit-card-logo-icon_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={32}
        height={24}
      >
        <path
          d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#credit-card-logo-icon_svg__a)">
        <path
          d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
          fill="url(#credit-card-logo-icon_svg__b)"
        />
        <path
          opacity={0.1}
          d="M37.5 69C54.897 69 69 54.897 69 37.5S54.897 6 37.5 6 6 20.103 6 37.5 20.103 69 37.5 69Z"
          fill="url(#credit-card-logo-icon_svg__c)"
        />
        <path
          opacity={0.1}
          d="M6.5 45C15.613 45 23 37.613 23 28.5S15.613 12 6.5 12-10 19.387-10 28.5-2.613 45 6.5 45Z"
          fill="url(#credit-card-logo-icon_svg__d)"
        />
        <path
          opacity={0.1}
          d="M42.5 22C57.136 22 69 10.136 69-4.5S57.136-31 42.5-31 16-19.136 16-4.5 27.864 22 42.5 22Z"
          fill="url(#credit-card-logo-icon_svg__e)"
        />
        <path
          d="M10 7H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1Z"
          fill="#171717"
        />
        <path
          d="M10 7H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1Z"
          fill="url(#credit-card-logo-icon_svg__f)"
        />
        <path opacity={0.3} d="M6 8H5v3h1V8ZM8 8H7v3h1V8ZM10 8H9v3h1V8Z" fill="#7A3802" />
        <path
          d="M9 15H4v1h5v-1ZM7 17H4v1h3v-1ZM11 17H8v1h3v-1ZM15 17h-3v1h3v-1ZM15 15h-5v1h5v-1ZM21 15h-5v1h5v-1ZM27 15h-5v1h5v-1Z"
          fill="#fff"
          fillOpacity={0.8}
        />
      </g>
      <defs>
        <linearGradient
          id="credit-card-logo-icon_svg__b"
          x1={-16}
          y1={12}
          x2={7.04}
          y2={-18.72}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#060B0E" />
          <stop offset={1} stopColor="#254764" />
        </linearGradient>
        <linearGradient
          id="credit-card-logo-icon_svg__c"
          x1={19.056}
          y1={23.639}
          x2={15.877}
          y2={14.93}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="credit-card-logo-icon_svg__d"
          x1={-0.838}
          y1={46.824}
          x2={-4.487}
          y2={32.148}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="credit-card-logo-icon_svg__e"
          x1={48.425}
          y1={-4.5}
          x2={54.285}
          y2={19.07}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="credit-card-logo-icon_svg__f"
          x1={11}
          y1={7}
          x2={11}
          y2={12}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAD961" />
          <stop offset={1} stopColor="#F7955D" />
        </linearGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = forwardRef(SvgCreditCardLogoIcon);
export default ForwardRef;
