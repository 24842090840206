import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgGiropayLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
        fill="#0F4CA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 17.25H19v-3.713c.436.813 1.391 1.115 2.199 1.115 1.987 0 3.051-1.68 3.051-3.701 0-1.653-1.012-3.451-2.858-3.451-1.052 0-1.93.433-2.392 1.391V7.5h-2.25v9.75Zm5.116-6.246c0 1.09-.526 1.837-1.397 1.837-.77 0-1.412-.748-1.412-1.746 0-1.023.565-1.784 1.412-1.784.897 0 1.397.787 1.397 1.693ZM15.25 7.655h-2.12v1.213h-.025c-.494-.826-1.3-1.368-2.3-1.368C8.699 7.5 7.75 8.998 7.75 11c0 1.989 1.157 3.382 3.016 3.382.936 0 1.715-.36 2.274-1.149h.026v.362c0 1.318-.727 1.95-2.08 1.95-.974 0-1.572-.206-2.287-.555l-.117 1.834a7.957 7.957 0 0 0 2.586.426c2.73 0 4.082-.892 4.082-3.655v-5.94Zm-5.082 3.305c0-.916.455-1.678 1.326-1.678 1.052 0 1.494.839 1.494 1.588 0 1.034-.663 1.73-1.494 1.73-.702 0-1.326-.594-1.326-1.64Z"
        fill="#FFFFFE"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgGiropayLogoIcon);
export default ForwardRef;
