import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgKingBedIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <g fill="none">
                <path d="M0 0h24v24H0z" />
                <path d="M0 0h24v24H0z" />
            </g>
            <path fill="none" d="M6 7h5v3H6zM13 7h5v3h-5z" />
            <path d="M20 10V7c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v3c-1.1 0-2 .9-2 2v5h1.33l.51 1.53c.1.28.36.47.66.47a.7.7 0 0 0 .66-.47L5.67 17h12.67l.51 1.53c.09.28.35.47.65.47a.7.7 0 0 0 .66-.47l.51-1.53H22v-5c0-1.1-.9-2-2-2zm-9 0H6V8c0-.55.45-1 1-1h4v3zm7 0h-5V7h4c.55 0 1 .45 1 1v2z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgKingBedIcon);
export default ForwardRef;
