import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

// Return type of the hook
interface UseTab<T> {
    initialTab: T;
    activeTab: T;
    handleTabChange: (value: string) => void;
}

/**
 *
 * This hook is used to manage the state of the tab navigation in the event overview page.
 * It handles the state of the active tab and updates the URL query parameter and session storage accordingly.
 * @returns initialTab: Tab, activeTab: Tab, handleTabChange: (value: string) void
 */
export const useTab = <T extends string>(
    defaultTab: T,
    isTab: (tab: string) => tab is T,
    setSearchParam: boolean = true,
): UseTab<T> => {
    const searchParams = useSearchParams();
    const [activeTab, setActiveTab] = useState<T>(defaultTab);

    const getInitialTab = useCallback((): T => {
        const tabFromURL = searchParams?.get('tab');
        const tabFromStorage =
            typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('activeTab') : null;

        if (tabFromURL && isTab(tabFromURL)) return tabFromURL;
        if (tabFromStorage && isTab(tabFromStorage)) return tabFromStorage;

        return defaultTab;
    }, [defaultTab, searchParams]);

    const handleTabChange = (value: string) => {
        if (!isTab(value)) {
            return;
        }

        //update the state
        setActiveTab(value);

        if (!setSearchParam) {
            return;
        }
        // update the URL query parameter
        const params = new URLSearchParams(searchParams?.toString() ?? {});
        params.set('tab', value);

        // update the URL query parameter
        window.history.pushState(null, '', `?${params.toString()}`);

        // Set in sessions storage
        sessionStorage.setItem('activeTab', value);
    };

    // Handle tab change when browser params change
    useEffect(() => {
        const params = new URLSearchParams(searchParams?.toString() ?? {});
        const tabFromURL = params.get('tab');

        if (tabFromURL && isTab(tabFromURL)) {
            setActiveTab(tabFromURL);
        }
    }, [searchParams]);

    return {
        initialTab: getInitialTab(),
        activeTab,
        handleTabChange,
    };
};
