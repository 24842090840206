import { HTMLAttributes, forwardRef } from 'react';
import { cn } from '../../../utils';

interface AuthBackgroundProps extends HTMLAttributes<HTMLDivElement> {
    imageUrl: string;
}

const AuthBackground = forwardRef<HTMLDivElement, AuthBackgroundProps>(
    ({ imageUrl, className, style, ...props }, forwardedRef) => {
        return (
            <div
                ref={forwardedRef}
                {...props}
                className={cn(className, 'shrink-0 object-cover object-center')}
                style={{
                    ...style,
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    width: '100%',
                    height: 'auto',
                }}
                draggable={false}
            />
        );
    },
);

AuthBackground.displayName = 'AuthBackground';

export default AuthBackground;
