import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgLogoBlueIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M14 28c-2.148 0-4.22-.268-6.214-.844-3.337-.92-6.022-3.605-6.942-6.942C.268 18.219 0 16.148 0 14c0-2.148.268-4.22.844-6.214.92-3.337 3.605-6.022 6.942-6.942C9.781.268 11.852 0 14 0c1.841 0 3.644.192 5.408.614 3.99.959 7.058 3.989 7.978 7.978.422 1.726.614 3.567.614 5.37 0 2.148-.268 4.219-.844 6.213-.92 3.337-3.605 6.022-6.942 6.943-1.995.614-4.066.882-6.214.882Z"
        fill="#5364FF"
      />
      <path
        d="M13.812 27.734c-2.119 0-4.162-.265-6.13-.833-3.292-.908-5.941-3.557-6.85-6.849C.266 18.084 0 16.041 0 13.922c0-2.12.265-4.163.833-6.13C1.74 4.498 4.39 1.85 7.682.941 9.65.374 11.693.109 13.812.109c1.817 0 3.595.19 5.336.606 3.936.946 6.963 3.935 7.871 7.87.417 1.704.606 3.52.606 5.299 0 2.119-.265 4.162-.833 6.13-.908 3.292-3.557 5.941-6.85 6.85-1.967.605-4.01.87-6.13.87Zm0-25.922c-1.967 0-3.86.265-5.676.757a8.138 8.138 0 0 0-5.638 5.638 21.841 21.841 0 0 0-.757 5.677c0 1.967.265 3.86.757 5.676A8.138 8.138 0 0 0 8.136 25.2c3.633 1.021 7.72 1.021 11.39 0a8.138 8.138 0 0 0 5.639-5.639c.492-1.816.757-3.746.757-5.676 0-1.665-.19-3.33-.568-4.92a8.95 8.95 0 0 0-6.622-6.584 21.326 21.326 0 0 0-4.92-.568Z"
        fill="#5364FF"
      />
      <path
        d="M13.812 7.413c-2.195 0-3.973 1.627-4.276 3.708h2.384a1.966 1.966 0 0 1 1.892-1.362c1.098 0 1.968.87 1.968 1.968 0 .87-.568 1.627-1.362 1.892v2.384a4.292 4.292 0 0 0 3.708-4.276c0-2.346-1.93-4.314-4.314-4.314ZM9.498 19.598h8.628v-2.346H9.498z"
        fill="#fff"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgLogoBlueIcon);
export default ForwardRef;
