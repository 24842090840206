import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgEventsIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m18.583 7.496-5.57-.815-2.501-5.059a.472.472 0 0 0-.853 0l-2.501 5.06-5.57.814c-.398.057-.55.55-.266.815l4.036 3.94-.947 5.552a.48.48 0 0 0 .7.493l4.984-2.615 4.983 2.615c.341.19.758-.114.682-.493l-.947-5.551 4.036-3.941a.475.475 0 0 0-.266-.815Z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgEventsIcon);
export default ForwardRef;
