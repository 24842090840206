'use client';

import { cn, optionsAnimation } from '../../utils';
import { Button } from '../button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandLoading,
} from '../command';
import { Popover, PopoverContent, PopoverTrigger } from '../popover';
import { CheckIcon, ExpandMoreIcon } from '@in2event/icons';
import { motion } from 'framer-motion';
import { useState } from 'react';

export interface AutocompleteProps<T> {
    search: string;
    setSearch: (value: string) => void;
    value: number | undefined;
    onSelect: (value: T) => void;
    items: T[];
    isLoading: boolean;
    getItemLabel: (item: T) => string;
    getItemKey: (item: T) => number;
    renderItem: (item: T) => React.ReactNode;
    // Text slugs
    placeholder: string;
    searchPlaceholder: string;
    loadingText: string;
    noResultsText: string;
}

export const Autocomplete = <T,>({
    search,
    setSearch,
    value,
    onSelect,
    items,
    isLoading,
    getItemLabel,
    getItemKey,
    renderItem,
    placeholder,
    searchPlaceholder,
    loadingText,
    noResultsText,
}: AutocompleteProps<T>) => {
    const [open, setOpen] = useState(false);

    const selected = items.find((item) => getItemKey(item) === value);

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="subtle"
                    role="combobox"
                    aria-expanded={open}
                    size="lg"
                    className={cn(
                        'w-full justify-between border-2 border-neutral-10 bg-neutral-2100 px-3 text-sm font-normal',
                        selected ? 'text-base-900' : 'text-neutral-50',
                    )}
                >
                    {selected ? getItemLabel(selected) : placeholder}
                    <ExpandMoreIcon className="ml-2 size-5 shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="w-full p-0 md:min-w-[480px]"
                onFocusOutside={(e) => e.preventDefault()}
                align="start"
            >
                <Command>
                    <CommandInput
                        placeholder={searchPlaceholder}
                        value={search}
                        onValueChange={setSearch}
                    />
                    <CommandList>
                        {isLoading ? (
                            <CommandLoading className="py-3 text-center text-sm">
                                {loadingText}
                            </CommandLoading>
                        ) : (
                            <CommandEmpty>{noResultsText}</CommandEmpty>
                        )}
                        <CommandGroup>
                            {items.map((item, i) => (
                                <motion.div
                                    key={getItemKey(item)}
                                    variants={optionsAnimation}
                                    initial="initial"
                                    animate={search ? 'disabled' : 'animate'}
                                    custom={getItemKey(item)}
                                    transition={{
                                        delay: search ? 0.05 : i * 0.03,
                                    }}
                                >
                                    <CommandItem
                                        key={getItemKey(item)}
                                        value={getItemLabel(item)}
                                        onSelect={() => {
                                            onSelect(item);
                                            setOpen(false);
                                        }}
                                    >
                                        <CheckIcon
                                            className={cn(
                                                'mr-2 size-5',
                                                getItemKey(item) === value
                                                    ? 'opacity-100'
                                                    : 'opacity-0',
                                            )}
                                        />
                                        {renderItem(item)}
                                    </CommandItem>
                                </motion.div>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};
