import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgLogo(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.013 0A5.006 5.006 0 0 0 0 5v30c0 2.761 2.244 5 5.013 5h30.075a5.006 5.006 0 0 0 5.012-5v-1.965a34.957 34.957 0 0 0-6.772-2.258c-1.346-.288-2.97-.479-3.878-.585-.435-.051-.706-.083-.703-.097-8.77-.693-15.474 2.282-17.687 3.428l-.078.035c-.417.19-.865.392-1.27.337-.383-.075-.614-.6-.665-.938-.167-1.108.544-2.465 1.144-3.367.613-.922 1.405-1.716 2.232-2.465a57.305 57.305 0 0 1 5.535-4.448c1.93-1.389 3.882-2.791 5.206-4.761 1.122-1.67 3.073-5.8 1.397-8.328-1.956-2.448-8.839-.35-10.445 3.822-.386 2.297.764 2.688 2.37 2.873l.613-.04 1.62-.108.14.175c.302.684-.71 2.196-1.179 2.897-.063.094-.116.174-.155.235-1.46 2.274-3.189 3.437-5.535 1.592-.491-.385-.968-.924-1.234-1.55-.426-1-.735-2.632-.452-4.087.865-4.472 3.412-7.141 7.257-8.645l1.258-.322.9-.23 1-.106.883-.094c4.899.071 9.872 2.26 8.48 8.673-.19.873-.435 1.804-.818 2.54-1.436 2.77-3.18 4.276-7.76 7.074-.415.253-.809.486-1.198.715-1.048.617-2.054 1.21-3.308 2.123-.718.524-1.75 1.675-1.75 1.675 3.395-1.14 8.489-2.156 14.379-2.156.013-.05 5.017.276 5.017.276 1.776.232 3.6.616 5.456 1.192V5c0-2.761-2.244-5-5.012-5H5.013Z"
        fill="url(#logo_svg__a)"
      />
      <defs>
        <linearGradient
          id="logo_svg__a"
          x1={0.359}
          y1={39.821}
          x2={40.06}
          y2={19.921}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF007A" />
          <stop offset={1} stopColor="#FF8A00" />
        </linearGradient>
      </defs>
    </svg>
  );
}
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
