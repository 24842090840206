import { cn } from '../../utils';
import ProgressBar from '../progress-bar';
import { StepsCancel } from '../steps';
import Heading from '../typography/heading';
import {
    Children,
    ComponentPropsWithoutRef,
    HTMLAttributes,
    ReactElement,
    isValidElement,
} from 'react';

// Root component for the layout header
type SingleAddLayoutHeaderRootProps = HTMLAttributes<HTMLHeadingElement>;
const SingleAddLayoutHeaderRoot = ({ className, ...props }: SingleAddLayoutHeaderRootProps) => (
    <header className={cn('sticky inset-x-0 top-0 z-10 border-b bg-white', className)} {...props} />
);

// Progress component for the layout header
type SingleAddLayoutHeaderProgressProps = Pick<
    ComponentPropsWithoutRef<typeof Heading>,
    'variant' | 'as'
> &
    HTMLAttributes<HTMLHeadingElement>;
const SingleAddLayoutHeaderProgress = ({
    children,
    className,
    ...props
}: SingleAddLayoutHeaderProgressProps) => (
    <Heading variant="title-3" as="h2" className={cn('max-md:text-base', className)} {...props}>
        {children}
    </Heading>
);

// Title component for the layout header
type SingleAddLayoutHeaderTitleProps = Pick<
    ComponentPropsWithoutRef<typeof Heading>,
    'variant' | 'as'
> &
    HTMLAttributes<HTMLHeadingElement>;
const SingleAddLayoutHeaderTitle = ({
    children,
    className,
    ...props
}: SingleAddLayoutHeaderTitleProps) => (
    <Heading
        variant="subtitle"
        as="h2"
        className={cn('text-neutral-250 max-md:text-sm', className)}
        {...props}
    >
        {children}
    </Heading>
);

// Actions component for the layout header
type SingleAddLayoutHeaderActionsProps = HTMLAttributes<HTMLDivElement>;
const SingleAddLayoutHeaderActions = ({
    className,
    ...props
}: SingleAddLayoutHeaderActionsProps) => (
    <div className={cn('flex items-center gap-2', className)} {...props} />
);

// Exported object containing all the components
export const SingleAddLayoutHeader = {
    // Root component
    Root: ({ children, ...props }: SingleAddLayoutHeaderRootProps) => {
        let progress: ReactElement | null = null;
        let title: ReactElement | null = null;
        let actions: ReactElement | null = null;
        let progressBar: ReactElement | null = null;

        // Iterate through children to find specific components
        Children.forEach(children, (child) => {
            if (isValidElement(child)) {
                if (child.type === SingleAddLayoutHeader.Progress) {
                    progress = child;
                } else if (child.type === SingleAddLayoutHeader.Title) {
                    title = child;
                } else if (child.type === SingleAddLayoutHeader.Actions) {
                    actions = child;
                } else if (child.type === SingleAddLayoutHeader.ProgressBar) {
                    progressBar = child;
                }
            }
        });

        return (
            <SingleAddLayoutHeaderRoot {...props}>
                <div className="mx-auto px-4 md:px-6">
                    <div className="flex h-16 items-center justify-between gap-2 md:gap-5">
                        <div className="flex items-center gap-2 md:gap-5">
                            {progress}
                            {title}
                        </div>
                        {actions}
                    </div>
                </div>
                {progressBar}
            </SingleAddLayoutHeaderRoot>
        );
    },
    // Progress component
    Progress: SingleAddLayoutHeaderProgress,
    // Title component
    Title: SingleAddLayoutHeaderTitle,
    // Actions component
    Actions: SingleAddLayoutHeaderActions,
    // Cancel component
    Cancel: StepsCancel,
    // ProgressBar component
    ProgressBar: ProgressBar,
};
