import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgSpeedMeter(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <mask
                id="speed-meter_svg__a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={24}
                height={24}
            >
                <path fill="#D9D9D9" d="M0 0h24v24H0z" />
            </mask>
            <g mask="url(#speed-meter_svg__a)">
                <path
                    d="M10.45 15.5c.417.417.942.613 1.575.587.633-.024 1.092-.254 1.375-.687l4.225-6.325c.15-.233.13-.446-.063-.637-.191-.192-.404-.213-.637-.063L10.6 12.6c-.433.3-.67.754-.713 1.363-.041.608.146 1.12.563 1.537ZM5.1 20c-.367 0-.704-.08-1.012-.238a1.783 1.783 0 0 1-.738-.712 11.205 11.205 0 0 1-1-2.438A9.734 9.734 0 0 1 2 14c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.138A9.738 9.738 0 0 1 12 4a9.63 9.63 0 0 1 3.85.775c1.2.517 2.25 1.22 3.15 2.112.9.892 1.617 1.934 2.15 3.125a9.578 9.578 0 0 1 .825 3.838 10.01 10.01 0 0 1-.313 2.688 9.82 9.82 0 0 1-1.037 2.512c-.183.317-.43.554-.738.712a2.184 2.184 0 0 1-1.012.238H5.1Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
const ForwardRef = forwardRef(SvgSpeedMeter);
export default ForwardRef;
