'use client';

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../alert-dialog';
import { Button } from '../button';
import { Sonner } from '../sonner';
import { useDialogPointer } from '../dialog';
import { WarningIcon } from '@in2event/icons';
import { useState } from 'react';

interface ActionDialogDeleteProps {
    action: () => Promise<{ success: boolean }>;
    trigger: React.ReactNode;
    onSuccessCallback?: () => void;
    onErrorCallback?: (error: unknown) => void;
    title: string;
    description: string;
    cancelButtonText: string;
    confirmButtonText: string;
    successMessage: string;
    errorMessage: string;
}

export const ActionDialogDelete = ({
    action,
    trigger,
    onSuccessCallback,
    onErrorCallback,
    title,
    description,
    cancelButtonText,
    confirmButtonText,
    successMessage,
    errorMessage,
}: ActionDialogDeleteProps) => {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useDialogPointer(open);

    const handleAction = async () => {
        try {
            // Start loading
            setIsLoading(true);
            const result = await action();

            // Check if the action was successful
            if (!result.success) {
                throw new Error(errorMessage);
            }
            // Close dialog
            setOpen(false);
            // Call the successful action callback
            onSuccessCallback?.();
            // Show toast
            Sonner.success(successMessage);
        } catch (error) {
            // Capture error
            onErrorCallback?.(error);
            // Show toast
            Sonner.error(errorMessage);
        } finally {
            // Stop loading
            setIsLoading(false);
        }
    };

    return (
        <AlertDialog open={open} onOpenChange={setOpen}>
            <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>
            <AlertDialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
                <AlertDialogHeader>
                    <AlertDialogTitle className="inline-flex items-center gap-x-3 text-red-500">
                        <WarningIcon className="size-6 text-red-500" />
                        {title}
                    </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogBody>
                    <AlertDialogDescription>{description}</AlertDialogDescription>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <AlertDialogCancel asChild>
                        <Button type="button" variant="subtle">
                            {cancelButtonText}
                        </Button>
                    </AlertDialogCancel>
                    <Button onClick={handleAction} variant="destructive" loading={isLoading}>
                        {confirmButtonText}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};
