import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgRestaurantIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 23 30"
            ref={ref}
            {...props}
        >
            <path
                fill="#626262"
                d="M4.5 10.5v-9q0-.637.431-1.069A1.45 1.45 0 0 1 6 0q.637 0 1.069.431.43.432.431 1.069v9H9v-9q0-.637.431-1.069A1.45 1.45 0 0 1 10.5 0q.637 0 1.069.431.43.432.431 1.069v9q0 2.1-1.294 3.675-1.293 1.575-3.206 2.1V28.5q0 .637-.431 1.069A1.45 1.45 0 0 1 6 30q-.637 0-1.069-.431A1.45 1.45 0 0 1 4.5 28.5V16.275q-1.913-.525-3.206-2.1Q0 12.6 0 10.5v-9Q0 .863.431.431A1.45 1.45 0 0 1 1.5 0q.637 0 1.069.431Q2.999.863 3 1.5v9zm15 7.5h-3q-.637 0-1.069-.431A1.45 1.45 0 0 1 15 16.5v-9q0-2.625 1.931-5.062T20.925 0q.675 0 1.125.525.45.526.45 1.237V28.5q0 .637-.431 1.069A1.45 1.45 0 0 1 21 30q-.637 0-1.069-.431A1.45 1.45 0 0 1 19.5 28.5z"
            />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgRestaurantIcon);
export default ForwardRef;
