import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';
import type { HtmlHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { cn } from '../../utils';

type HeadingElementProps = HtmlHTMLAttributes<HTMLHeadingElement>;
type HeadingElement = React.ElementRef<'h1'>;

interface HeadingAsChildProps {
    asChild: true;
    as?: never;
}
interface HeadingAsProps {
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    asChild?: false;
}

const headingVariants = cva('font-normal', {
    variants: {
        variant: {
            default: 'text-base-900 text-xl font-bold leading-[26px] tracking-[-1%]',
            display: '-tracking-4 text-base-900 text-4xl font-bold',
            subtitle: 'font-medium text-neutral-60 text-base',
            large: 'text-2xl font-bold text-base-900',
            'title-1': 'text-xl font-bold leading-6 tracking-[-1%] text-base-900',
            'title-2': 'text-lg font-bold leading-6 tracking-[-1%] text-base-900',
            'title-3': 'text-base font-bold text-base-900',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});

type HeadingProps = HeadingElementProps &
    VariantProps<typeof headingVariants> &
    (HeadingAsChildProps | HeadingAsProps);

const Heading = forwardRef<HeadingElement, HeadingProps>(
    ({ className, as: Tag = 'h1', asChild, children, variant, ...props }, ref) => {
        return (
            <Slot {...props} className={cn(headingVariants({ variant, className }))} ref={ref}>
                {asChild ? children : <Tag>{children}</Tag>}
            </Slot>
        );
    },
);

Heading.displayName = 'Heading';

export default Heading;
