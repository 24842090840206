import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgBadgeIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 -960 960 960"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <path d="M232-247h239v-14q0-18-9-32t-23-19q-32-11-50-14.5t-35-3.5q-19 0-40.5 4.5T265-312q-15 5-24 19t-9 32v14Zm361-67h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q738-350 730.917-357q-7.084-7-17.917-7H593q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q568-328 575.083-321q7.084 7 17.917 7Zm-239-50q22.5 0 38.25-15.75T408-418q0-22.5-15.75-38.25T354-472q-22.5 0-38.25 15.75T300-418q0 22.5 15.75 38.25T354-364Zm239-63h120q10.833 0 17.917-7.116 7.083-7.117 7.083-18Q738-463 730.917-470q-7.084-7-17.917-7H593q-10.833 0-17.917 7.116-7.083 7.117-7.083 18Q568-441 575.083-434q7.084 7 17.917 7ZM140-80q-24 0-42-18t-18-42v-480q0-24 18-42t42-18h250v-140q0-24 18-42t42.411-18h59.178Q534-880 552-862t18 42v140h250q24 0 42 18t18 42v480q0 24-18 42t-42 18H140Zm310-510h60v-230h-60v230Z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgBadgeIcon);
export default ForwardRef;
