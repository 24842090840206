import * as icons from '@in2event/icons';
import {
    forwardRef,
    type ForwardRefExoticComponent,
    type RefAttributes,
    type SVGProps,
} from 'react';

type IconType = {
    [key: string]: ForwardRefExoticComponent<
        Omit<SVGProps<SVGSVGElement>, 'ref'> & RefAttributes<SVGSVGElement>
    >;
};

interface DynamicIconProps extends SVGProps<SVGSVGElement> {
    icon: string;
}

const DynamicIcon = forwardRef<SVGSVGElement, DynamicIconProps>(({ icon, ...props }, ref) => {
    const iconMap: IconType = icons;
    const Icon = iconMap[icon];

    if (!Icon) {
        return null;
    }

    return <Icon {...props} ref={ref} />;
});

DynamicIcon.displayName = 'DynamicIcon';

export default DynamicIcon;
