import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgPersonAcceptIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <mask
                id="person-accept-icon_svg__a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={25}
                height={25}
            >
                <path fill="currentColor" d="M.062.474h24v24h-24z" />
            </mask>
            <g mask="url(#person-accept-icon_svg__a)">
                <path
                    d="m17.612 9.649 3.525-3.55c.2-.2.437-.3.712-.3.275 0 .513.1.713.3.2.2.3.437.3.712 0 .275-.1.513-.3.713l-4.25 4.25c-.2.2-.434.3-.7.3a.96.96 0 0 1-.7-.3l-2.125-2.125c-.2-.2-.3-.438-.3-.713 0-.275.1-.512.3-.712.2-.2.433-.3.7-.3.266 0 .5.1.7.3l1.425 1.425Zm-8.55 2.825c-1.1 0-2.042-.392-2.825-1.175-.784-.783-1.175-1.725-1.175-2.825s.391-2.042 1.175-2.825c.783-.783 1.725-1.175 2.825-1.175s2.041.392 2.825 1.175c.783.783 1.175 1.725 1.175 2.825s-.392 2.042-1.175 2.825c-.784.783-1.725 1.175-2.825 1.175Zm-8 6v-.8c0-.567.145-1.088.437-1.563.292-.475.68-.837 1.163-1.087a14.854 14.854 0 0 1 3.15-1.163 13.76 13.76 0 0 1 3.25-.387c1.1 0 2.183.13 3.25.387 1.066.259 2.116.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563v.8c0 .55-.196 1.02-.588 1.412a1.926 1.926 0 0 1-1.412.588h-12c-.55 0-1.021-.196-1.413-.588a1.926 1.926 0 0 1-.587-1.412Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
}
const ForwardRef = forwardRef(SvgPersonAcceptIcon);
export default ForwardRef;
