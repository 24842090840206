import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgEpsLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
        fill="#DE378C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.698 14.943s-.105-.505-.105-.838c0-.333.1-.843.1-.843.367-1.525 1.71-2.66 3.307-2.66 1.878 0 3.42 1.567 3.42 3.495v.848h-4.99c.294.59.89.923 1.577.923h4.487l.006-5c0-.745-.594-1.354-1.32-1.354h-6.36c-.726 0-1.32.591-1.32 1.336v6.526c0 .745.594 1.374 1.32 1.374h6.36c.651 0 1.195-.48 1.3-1.108h-4.473c-1.6 0-2.945-1.17-3.309-2.699Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.87 12.152c-.67 0-1.252.462-1.542 1.056h3.086c-.29-.594-.873-1.056-1.543-1.056ZM18.7 7.429c0-1.618-1.267-2.929-2.83-2.929-1.536 0-2.786 1.268-2.827 2.849v.9c0 .105.081.21.183.21h1.04c.102 0 .191-.105.191-.21v-.82c0-.808.634-1.465 1.415-1.465.78 0 1.414.657 1.414 1.465v.82c0 .105.082.21.184.21h1.041c.102 0 .19-.105.19-.21v-.82Z"
        fill="#fff"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgEpsLogoIcon);
export default ForwardRef;
