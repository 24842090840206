import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgUncheckedCircleIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <rect x={1} y={1} width={22} height={22} rx={11} fill="#F6F6F6" />
      <rect x={1} y={1} width={22} height={22} rx={11} stroke="#DCDCDC" strokeWidth={2} />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgUncheckedCircleIcon);
export default ForwardRef;
