import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgBankTransferLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M6.71 9.745v5.347h4.456V9.745H6.711ZM21.247 9.68h-4.474v5.375h4.474V9.68Z"
        fill="#fff"
      />
      <path d="M20.68 15.055h5.338V9.68H20.68v5.375Z" fill="#fff" />
      <path
        d="M4.129.5h23.742c2 0 3.629 1.64 3.629 3.674v15.652c0 2.034-1.63 3.674-3.629 3.674H4.129c-2 0-3.629-1.64-3.629-3.674V4.174C.5 2.14 2.13.5 4.129.5Z"
        fill="#fff"
        stroke="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.006 11.431H9.683c0-.22-.025-.369-.076-.444-.079-.112-.296-.168-.652-.168-.345 0-.574.032-.686.097-.111.064-.167.205-.167.421 0 .196.05.325.149.386a.61.61 0 0 0 .282.078l.266.019c.572.038.93.066 1.071.081.453.047.78.169.984.367.16.155.255.357.286.607.017.149.026.318.026.506 0 .432-.04.75-.122.95-.147.369-.478.602-.992.7-.216.04-.546.061-.99.061-.74 0-1.257-.045-1.548-.135-.358-.11-.594-.334-.708-.67-.064-.189-.095-.501-.095-.938h1.323v.111c0 .234.066.384.197.45.092.047.202.071.331.074h.486c.248 0 .407-.013.475-.039a.39.39 0 0 0 .24-.235.914.914 0 0 0 .03-.26c0-.236-.085-.38-.255-.431-.063-.02-.358-.05-.884-.085-.423-.031-.717-.06-.881-.09-.433-.082-.722-.251-.866-.508-.126-.22-.19-.55-.19-.993 0-.337.035-.607.103-.81a.886.886 0 0 1 .331-.463c.223-.163.508-.258.856-.286.289-.026.61-.039.962-.039.555 0 .95.033 1.186.097.576.157.864.598.864 1.322 0 .06-.005.149-.013.268ZM18.195 12.42h.886c.282-.01.453-.032.514-.066.084-.047.14-.141.169-.283a2.42 2.42 0 0 0 .026-.419c0-.227-.017-.396-.053-.506-.051-.154-.174-.248-.368-.282a2.81 2.81 0 0 0-.276-.008h-.898v1.564Zm-1.422 2.635V9.68h2.649c.364 0 .64.03.831.09a1.3 1.3 0 0 1 .878.853c.077.226.116.566.116 1.02 0 .544-.043.936-.128 1.175-.17.472-.52.743-1.047.814-.063.01-.327.02-.793.027l-.236.008h-.848v1.388h-1.422ZM24.025 13.08l-.667-2.331-.646 2.33h1.313Zm.282 1.046h-1.901l-.255.93H20.68l1.587-5.376h2.145l1.606 5.375h-1.44l-.271-.929Z"
        fill="#0054A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.869 10.36c.586 0 1.11.276 1.454.706l.306-.668a2.662 2.662 0 0 0-1.851-.746c-1.12 0-2.079.689-2.472 1.664h-.598l-.353.77h.77a2.655 2.655 0 0 0 .003.477h-.386l-.353.77h.935a2.665 2.665 0 0 0 2.454 1.617c.556 0 1.071-.17 1.498-.46v-.943c-.344.398-.846.65-1.407.65a1.865 1.865 0 0 1-1.568-.864h1.96l.352-.77h-2.6a1.983 1.983 0 0 1-.011-.478h2.83l.353-.77h-2.938c.325-.57.93-.954 1.622-.954Z"
        fill="#FEC10E"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgBankTransferLogoIcon);
export default ForwardRef;
