import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgBillingIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667Zm1.175 13.408v.483c0 .609-.5 1.109-1.108 1.109h-.009c-.608 0-1.108-.5-1.108-1.109v-.5c-1.108-.233-2.092-.841-2.508-1.866-.192-.459.166-.967.666-.967h.2c.309 0 .559.208.675.5.242.625.875 1.058 2.092 1.058 1.633 0 2-.816 2-1.325 0-.691-.367-1.341-2.225-1.783-2.067-.5-3.483-1.35-3.483-3.058 0-1.434 1.158-2.367 2.591-2.675v-.5c0-.609.5-1.109 1.109-1.109h.008c.608 0 1.108.5 1.108 1.109v.516c1.15.284 1.875 1 2.192 1.884a.708.708 0 0 1-.675.941h-.217c-.308 0-.558-.216-.641-.516-.192-.634-.717-1.042-1.767-1.042-1.25 0-2 .567-2 1.367 0 .7.542 1.158 2.225 1.591 1.683.434 3.483 1.159 3.483 3.259-.016 1.525-1.158 2.358-2.608 2.633Z"
        fill="currentColor"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgBillingIcon);
export default ForwardRef;
