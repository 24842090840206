import { DateRangeIcon } from '@in2event/icons';
import { PopoverTrigger } from '@radix-ui/react-popover';
import { format, isValid, parse, subYears } from 'date-fns';
import { forwardRef, useEffect, useState } from 'react';
import type { ChangeEventHandler, ComponentPropsWithoutRef, ElementRef } from 'react';
import type { Matcher } from 'react-day-picker';
import { Calendar } from '../calendar';
import { InputText } from '../input-text';
import { Popover, PopoverContent, PopoverAnchor } from '../popover';

interface InputDatePickerProps
    extends Omit<ComponentPropsWithoutRef<typeof InputText>, 'value' | 'onChange' | 'label'> {
    dateFormat?: string;
    disabledDates?: Matcher | Matcher[] | undefined;
    startMonth?: Date | undefined;
    endMonth?: Date | undefined;
    inputValue?: string | undefined;
    onValueChange?: (value: string) => void;
    defaultMonth?: Date | undefined;
}

const today = new Date();

function addHyphens(input: string) {
    input = input.replace(/\D/g, '');

    // Add hyphen after month (if necessary)

    if (input.length >= 2) {
        input = input.substring(0, 2) + '-' + input.substring(2);
    }

    // Add hyphen after day (if necessary)

    if (input.length >= 5) {
        input = input.substring(0, 5) + '-' + input.substring(5);
    }

    return input;
}

export const InputDatePicker = forwardRef<ElementRef<typeof InputText>, InputDatePickerProps>(
    function InputDatePicker(
        {
            dateFormat = 'dd-MM-yyyy',
            disabledDates = [],
            startMonth,
            endMonth,
            inputValue,
            onValueChange,
            defaultMonth = subYears(new Date(), 18),
            ...props
        },
        ref,
    ) {
        const [selected, setSelected] = useState<Date>();
        const [isPopperOpen, setIsPopperOpen] = useState(false);

        const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
            const valueWithHyphens = addHyphens(e.currentTarget.value);
            onValueChange?.(valueWithHyphens);

            const date = parse(e.currentTarget.value, dateFormat, new Date());
            setSelected?.(isValid(date) ? date : undefined);
        };

        const handleDaySelect = (date: Date | undefined) => {
            setSelected?.(date);
            if (date) {
                onValueChange?.(format(date, dateFormat));
                closePopper();
            } else {
                onValueChange?.('');
            }
        };

        const closePopper = () => {
            setIsPopperOpen(false);
        };

        useEffect(() => {
            if (inputValue) {
                const date = parse(inputValue, dateFormat, new Date());

                setSelected?.(isValid(date) ? date : undefined);
            } else {
                setSelected?.(undefined);
            }
        }, []);

        return (
            <Popover open={isPopperOpen} onOpenChange={setIsPopperOpen} modal>
                <PopoverAnchor className="relative">
                    <InputText
                        {...props}
                        ref={ref}
                        size={12}
                        value={inputValue}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                                const input = e.target as HTMLInputElement;
                                // If the input is null or undefined, do nothing
                                if (!input.selectionStart || !input.selectionEnd) return;
                                if (input.value[input.selectionStart - 1] === '-') {
                                    e.preventDefault();
                                    // Move cursor one position to the left
                                    input.setSelectionRange(
                                        input.selectionStart - 1,
                                        input.selectionEnd - 1,
                                    );
                                }
                            }
                        }}
                        onChange={handleInputChange}
                    />
                    <PopoverTrigger
                        disabled={props.disabled}
                        className="disabled:cursor-not-allowed"
                        asChild
                    >
                        <DateRangeIcon className="absolute right-2 top-2 size-6 cursor-pointer text-neutral-750" />
                    </PopoverTrigger>
                </PopoverAnchor>
                <PopoverContent className="z-50 w-auto bg-white p-0" align="start" forceMount>
                    <Calendar
                        mode="single"
                        selected={selected}
                        onSelect={handleDaySelect}
                        defaultMonth={defaultMonth}
                        onDayClick={handleDaySelect}
                        disabled={disabledDates}
                        captionLayout="dropdown"
                        startMonth={startMonth}
                        endMonth={endMonth}
                        weekStartsOn={1}
                    />
                </PopoverContent>
            </Popover>
        );
    },
);
