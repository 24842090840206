import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgMachineryIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <g fill="none">
                <path d="M0 0h24v24H0z" />
                <path d="M0 0h24v24H0z" />
            </g>
            <path d="m19.93 8.35-3.6 1.68L14 7.7V6.3l2.33-2.33 3.6 1.68c.38.18.82.01 1-.36.18-.38.01-.82-.36-1l-3.92-1.83a.993.993 0 0 0-1.13.2L13.78 4.4A.975.975 0 0 0 13 4c-.55 0-1 .45-1 1v1H8.82C8.34 4.66 6.96 3.75 5.4 4.06c-1.17.23-2.13 1.19-2.35 2.36-.25 1.34.4 2.54 1.43 3.16L7.08 18H5.5c-.83 0-1.5.67-1.5 1.5S4.67 21 5.5 21h10c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-2.12L8.41 8.77c.17-.24.31-.49.41-.77H12v1c0 .55.45 1 1 1 .32 0 .6-.16.78-.4l1.74 1.74c.3.3.75.38 1.13.2l3.92-1.83c.38-.18.54-.62.36-1a.753.753 0 0 0-1-.36zM6 8c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMachineryIcon);
export default ForwardRef;
