import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgChatIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 24 24"
            ref={ref}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M5.136 8.002c1.5-2.475 4.271-4 7.27-4.002 4.636 0 8.394 3.58 8.394 7.998 0 4.417-3.758 7.997-8.393 7.997a8.6 8.6 0 0 1-4.492-1.222l-3.452 1.222a.4.4 0 0 1-.114 0 .34.34 0 0 1-.278-.122.31.31 0 0 1-.058-.287l1.122-3.583a7.68 7.68 0 0 1 .001-8"
                clipRule="evenodd"
            />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgChatIcon);
export default ForwardRef;
