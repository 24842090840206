import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgOnSiteSolutionsIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      ref={ref}
      {...props}
    >
      <path d="M0 0h24v24H0z" fill="none" opacity={0.1} />
      <path d="M12 1a9 9 0 0 0-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h4v1h-7v2h6c1.66 0 3-1.34 3-3V10a9 9 0 0 0-9-9z" />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgOnSiteSolutionsIcon);
export default ForwardRef;
