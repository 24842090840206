import { cn } from '../../utils';
import { HTMLAttributes } from 'react';

type SingleAddLayoutRootProps = HTMLAttributes<HTMLDivElement>;

export const SingleAddLayoutRoot = ({ className, ...props }: SingleAddLayoutRootProps) => {
    return (
        <div
            className={cn('fixed inset-0 flex h-screen flex-col bg-white', className)}
            {...props}
        />
    );
};
