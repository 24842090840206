import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgFormatUnderlineIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 24 24"
            width="1em"
            fill="currentColor"
            ref={ref}
            {...props}
        >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M12.79 16.95c3.03-.39 5.21-3.11 5.21-6.16V4.25a1.25 1.25 0 0 0-2.5 0v6.65c0 1.67-1.13 3.19-2.77 3.52A3.494 3.494 0 0 1 8.5 11V4.25a1.25 1.25 0 0 0-2.5 0V11c0 3.57 3.13 6.42 6.79 5.95zM5 20c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgFormatUnderlineIcon);
export default ForwardRef;
