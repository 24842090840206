'use client';

import * as React from 'react';
import { OTPInput, OTPInputContext } from 'input-otp';
import { cn } from '../../utils';

const InputOTP = React.forwardRef<
    React.ElementRef<typeof OTPInput>,
    React.ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, containerClassName, ...props }, ref) => (
    <OTPInput
        ref={ref}
        containerClassName={cn(
            'flex items-center gap-2 has-[:disabled]:opacity-50',
            containerClassName,
        )}
        className={cn('disabled:cursor-not-allowed', className)}
        {...props}
    />
));
InputOTP.displayName = 'InputOTP';

const InputOTPGroup = React.forwardRef<
    React.ElementRef<'div'>,
    React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center space-x-2', className)} {...props} />
));
InputOTPGroup.displayName = 'InputOTPGroup';

const InputOTPSlot = React.forwardRef<
    React.ElementRef<'div'>,
    React.ComponentPropsWithoutRef<'div'> & { index: number; secure?: boolean }
>(({ index, className, secure = false, ...props }, ref) => {
    const inputOTPContext = React.useContext(OTPInputContext);
    const slot = inputOTPContext.slots[index];
    const { char, hasFakeCaret, isActive } = slot || {};

    const [maskedValue, setMaskedValue] = React.useState<string | null>(null);

    // Set masked value with a delay to prevent flickering
    React.useEffect(() => {
        if (char && secure) {
            setMaskedValue(char);
            const timeoutId = setTimeout(() => {
                setMaskedValue(char.replace(/\d/g, '*'));
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [char]);

    return (
        <div
            ref={ref}
            className={cn(
                'relative flex h-16 w-[53px] items-center justify-center rounded-md border-2 border-neutral-10 text-[28px] font-light leading-[34px] transition-all',
                isActive && 'z-10 border-primary-600',
                className,
            )}
            {...props}
        >
            {char ? (
                <span className={cn('pointer-events-none', maskedValue === '*' && 'mt-2')}>
                    {secure ? maskedValue : char}
                </span>
            ) : null}
            {hasFakeCaret && (
                <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
                    <div className="h-8 w-px animate-caret-blink bg-neutral-200 duration-700" />
                </div>
            )}
        </div>
    );
});
InputOTPSlot.displayName = 'InputOTPSlot';

const InputOTPSeparator = React.forwardRef<
    React.ElementRef<'div'>,
    React.ComponentPropsWithoutRef<'div'>
>(({ ...props }, ref) => <div ref={ref} role="separator" {...props} />);
InputOTPSeparator.displayName = 'InputOTPSeparator';

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
