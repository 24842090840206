import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgSearchIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={ref}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.816 15.132a6.316 6.316 0 1 1 5.005-2.464l3.44 3.44a.816.816 0 1 1-1.154 1.153l-3.44-3.44a6.289 6.289 0 0 1-3.851 1.31Zm4.737-6.316a4.737 4.737 0 1 1-9.474 0 4.737 4.737 0 0 1 9.474 0Z"
                fill="currentColor"
            />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgSearchIcon);
export default ForwardRef;
