import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgQuoteLeftIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M.403 21v-6.136c0-1.743.341-3.523 1.023-5.341A23.387 23.387 0 0 1 4.125 4.38C5.242 2.77 6.455 1.426 7.761.347L13.102 3.5a28.085 28.085 0 0 0-2.613 5.227c-.663 1.819-.995 3.845-.995 6.08V21H.404Zm14.347 0v-6.136c0-1.743.34-3.523 1.023-5.341a23.388 23.388 0 0 1 2.699-5.142c1.117-1.61 2.33-2.955 3.636-4.034l5.34 3.153a28.08 28.08 0 0 0-2.613 5.227c-.663 1.819-.994 3.845-.994 6.08V21H14.75Z"
        fill="#E3E3E3"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgQuoteLeftIcon);
export default ForwardRef;
