import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgBelfiusLogoIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
      {...props}
    >
      <path
        d="M28 0H4a4 4 0 0 0-4 4v16a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4Z"
        fill="#D50043"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 8.531c0-.984.79-1.781 1.781-1.781h6.938c.983 0 1.781.79 1.781 1.781v8.719h-8.719c-.984 0-1.781-.79-1.781-1.781V8.53ZM13 10.412v.176c0 .373.298.662.665.662h4.67a.658.658 0 0 0 .665-.662v-.176a.659.659 0 0 0-.665-.662h-4.67a.658.658 0 0 0-.665.662Zm0 3v.176c0 .373.298.662.665.662h4.67a.658.658 0 0 0 .665-.662v-.176a.659.659 0 0 0-.665-.662h-4.67a.658.658 0 0 0-.665.662Z"
        fill="#fff"
      />
    </svg>
  );
}
const ForwardRef = forwardRef(SvgBelfiusLogoIcon);
export default ForwardRef;
