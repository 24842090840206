import { forwardRef } from 'react';
import type { SVGProps, Ref } from 'react';
function SvgChatUnreadIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            viewBox="0 0 24 24"
            ref={ref}
            {...props}
        >
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M14.002 4.144a6 6 0 0 0 6.528 5.833c.176.645.27 1.323.27 2.02 0 4.418-3.758 7.998-8.393 7.998a8.6 8.6 0 0 1-4.492-1.222l-3.452 1.222a.4.4 0 0 1-.114 0 .34.34 0 0 1-.278-.122.31.31 0 0 1-.058-.287l1.122-3.583a7.68 7.68 0 0 1 .001-8c1.5-2.476 4.271-4.001 7.27-4.003q.82.002 1.596.144"
                clipRule="evenodd"
            />
            <circle cx={20} cy={4} r={4} fill="#F95724" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgChatUnreadIcon);
export default ForwardRef;
